import React from 'react';
import { LoginForm } from '../../components/FormsComponent/LoginForm';
import './AuthPage.scss';
import bgImage from '../../assets/images/auth-bg.svg';
import Logo from '../../assets/images/logo2.png';
function LoginPage() {
  return (
    <div className="relative max-h-screen h-full px-4">
      <img
        src={bgImage}
        alt="image"
        className="absolute top-0 right-0 left-0 bottom-0 h-screen object-cover w-full opacity-50"
      />
      <div className="absolute z-10 flex p-3 top-0 right-0 left-0 bottom-0 h-screen object-cover w-full">
        <div className="bg-[#ffffff] shadow-2xl rounded-xl p-6 max-w-[470px] flex flex-col m-auto">
          <div className="self-center">
            <img src={Logo} className="h-20" alt="logo" />
          </div>
          <LoginForm />
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
