import React, { useContext, useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import "./Dashboard.scss";
import DashboardSideNav, {
  DashboardTopNav,
} from "../../components/Nav/Nav.jsx";
import { AuthContext } from "./../../context/AuthContext";
import { PiSpinner } from "react-icons/pi";
import Modal from "../../components/Modal/Modals.jsx";
import WelcomeModal from "../../components/Modal/WelcomeModal.jsx";

function Dashboard() {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [isWelcomeModal, setIsWelcomeModalOpen] = useState(false);

  const { isLoggedIn, isLoading ,userProfile } = useContext(AuthContext);


  useEffect(() => {
    if (!userProfile?.survey) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }

    if (localStorage.getItem('CreateProjTutorial') != "SEEN" && userProfile?.account_type == "client") {
      localStorage.setItem('CreateProjTutorial', 'SEEN');
      setIsWelcomeModalOpen(true);
    } else {

    }
  }, [userProfile]); 

  if (isLoading) {
    return (
      <div className='session_expired'>
        <PiSpinner className='text-[#000aff] h-20 w-20 animate-spin' />
      </div>
    );
  }
  if (!isLoggedIn) {
    return (
      <div className='session_expired'>
        Login Session Expired Login again <br />
        <Link to={"/login"}>Login</Link>
      </div>
    );
  }

  return (
    <div className='max-h-screen w-full h-full grid lg:grid-cols-11 bg-native-blue'>
      <div className='bg-native-blue pr-5 hidden lg:flex flex-col lg:col-span-3 xl:col-span-2 h-full min-h-screen w-full'>
        <DashboardSideNav
          sideNavOpen={sideNavOpen}
          setSideNavOpen={setSideNavOpen}
        />
      </div>
      <div className='lg:col-span-8 custom-scrollbar overflow-auto xl:col-span-9 bg-gray-100 lg:rounded-bl-[60px] lg:rounded-tl-[60px] h-screen w-full'>
        <DashboardTopNav />
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <WelcomeModal isOpen={isWelcomeModal} setIsWelcomeModalOpen={setIsWelcomeModalOpen} onClose={() => setIsWelcomeModalOpen(false)} />
        <div className='pb-10'>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
