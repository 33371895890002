import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import faker from "faker";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      // position: 'top' as const,
    },
    title: {
      display: true,
      // text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [15, 11, 6, 15, 12, 14, 20],
      // backgroundColor: "rgba(255, 99, 132, 0.5)",
      backgroundColor: "#0000FF",
    },
    {
      label: "Dataset 2",
      data: [12, 19, 13, 6, 7, 9, 28],
      // backgroundColor: "rgba(53, 162, 235, 0.5)",
      // backgroundColor: "#145C9E ",
      backgroundColor: "rgba(0, 0, 255, 0.5)",
    },
  ],
};
function BarChatComponent() {
  return <Bar options={options} data={data} />;
}

export default BarChatComponent;
