import React from 'react';

function SuccessStep() {
  return (
    <div className="SuccessStep">
      <h2>🎉 Welcome to Urbex App,</h2>

      <p>
      Your All-in-One Solution for Project Management, Reporting, document control!</p>
      <p>We are thrilled to have you on board with our cutting-edge software designed to streamline your project workflows, enhance reporting capabilities and improve project execution.</p><p> As an individual, Urbex is here to take away the worries and insecurities that come with carrying out construction projects.
      </p>
    </div>
  );
}

export default SuccessStep;
