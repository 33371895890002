import React, { useEffect, useState } from "react";
import OngoingProjectCardComponent, {
  ViewOngoingProjectCardComponent,
} from "../OngoingProjectCardComponent/OngoingProjectCardComonent";
import { useNavigate } from "react-router-dom";

function OverviewComponent({ userProfile }) {
  const [isContractor, setIsContractor] = useState(false);
  const navigate = useNavigate();
  // console.log('userprofile', userProfile)
  useEffect(() => {
    if (
      userProfile?.account_type === "Contractor" ||
      userProfile?.account_type === "contractor"
    ) {
      setIsContractor(true);
    }
  }, [userProfile]);
  return (
    <div className='over-view-container bg-[#393838] px-4 py-4 mb-2 rounded-xl border'>
      <h3 className='text-white text-2xl font-bold'>Overview</h3>
      <div className='grid md:grid-cols-2 gap-4'>
        <div>
          <div className='row sub-gride justify-content-between'>
            <div className='grid grid-cols-2 p-3 my-2 rounded-xl bg-[#111] text-white'>
              <div
                onClick={() =>
                  isContractor ? null : navigate("/dashboard/ongoing-projects/")
                }
              >
                <span className='text-gray-400'>
                  {isContractor
                    ? "Total Bids Submitted"
                    : "Total Project Listed "}{" "}
                </span>
                <h2>
                  {isContractor
                    ? userProfile?.overview?.bids_submitted
                    : userProfile?.overview?.projects_listed}
                </h2>
              </div>
              <div>
                <span className='text-gray-400'>
                  {isContractor ? "Bids Accepted" : "Bids Received "}
                </span>
                <h2>
                  {" "}
                  {isContractor
                    ? userProfile?.overview?.bids_accepted
                    : userProfile?.overview?.bids_received}
                </h2>
              </div>
            </div>
            {/* contractor */}
            <div className='grid grid-cols-2 mb-2 p-0 gap-2 rounded-xl text-white'>
              <div
                onClick={() =>
                  isContractor ? null : navigate("/dashboard/ongoing-projects/")
                }
                className='bg-[#111111] p-3 rounded-xl text-white my-0 h-100 justify-content-center'
              >
                <span className='text-sm text-gray-400'>
                  {isContractor ? "Execution Rate" : "Ongoing Projects"}
                </span>
                <h2>
                  {isContractor
                    ? `${userProfile?.overview?.ratings}%`
                    : userProfile?.overview?.ongoing_projects}
                </h2>
              </div>
              <div className='bg-[#111111] p-3 rounded-xl my-0 h-100 justify-content-center'>
                <span className='text-sm text-gray-400'>
                  {isContractor ? "Project Reviews" : "Project Reviews"}
                </span>
                <h2>0</h2>
              </div>
            </div>

            <div className='bg-[#111111] text-white p-2 rounded-xl col-12'>
              <span className='text-gray-400'>
                Total Amount {isContractor ? "Spent" : "Spent"}
              </span>
              <div className='flex items-end gap-2'>
                <h2>{userProfile?.overview?.raw_amount_spent}</h2>
                <span>NGN</span>
              </div>
            </div>
          </div>
        </div>
        <div className='compass-container m-0'>
          <OngoingProjectCardComponent profile={userProfile} />
        </div>
      </div>
    </div>
  );
}

export default OverviewComponent;

export function ProjectOverviewComponent({ project }) {
  useEffect(() => {
    // console.log(
    //   "overview",
    //   project?.project_milestones_set,
    //   project?.project_milestones_completed
    // );
    // setOverView(userProfile?.overview);
  }, [project]);
  function calculatePercentage(total, completed) {
    const percentage = (Number(completed) / Number(total)) * 100;

    if (isNaN(Number(percentage))) {
      return 0;
    }
    return Math.round(percentage);
  }
  return (
    <>
      <div className='over-view-container cursor-pointer mt-7 rounded-lg bg-white p-4'>
        <h3 className='text-2xl font-bold'>Overview</h3>
        <div className='flex flex-col lg:grid xl:grid-cols-2 gap-4'>
          <div className=''>
            <div className='grid grid-cols-2 gap-2 p-0 my-2 rounded-xl text-white'>
              <div className='p-3 rounded-lg bg-[#111111]'>
                <span className='text-sm'>Completion</span>
                <h2>
                  {calculatePercentage(
                    project?.project_milestones_set,
                    project?.project_milestones_completed
                  )}
                  %
                </h2>
              </div>
              <div className='p-3 rounded-lg bg-[#111111]'>
                <div className='my-0 h-100 justify-content-center'>
                  <div className='flex text-sm'>
                    Days {project?.project_days_left.split(" ")[2]}
                  </div>
                  <h2> {project?.project_days_left.split(" ")[0]}</h2>
                </div>
              </div>
            </div>
            <div className='flex flex-column bg-[#111111] items-start justify-center rounded-xl px-4 text-white h-24'>
              <span className='text-sm'>Total Amount Funded</span>
              <div className='flex items-end gap-2'>
                <h2>{project?.raw_total_credits}</h2>
                <span>NGN</span>
              </div>
            </div>
            {/* contractor */}

            <div className='flex flex-column bg-[#111111] items-start justify-center rounded-xl px-4 text-white h-24 mt-2'>
              <span className='text-sm'>Total Amount Spent</span>
              <div className='flex items-end gap-2'>
                <h2>{project?.raw_total_debits}</h2>
                <span>NGN</span>
              </div>
            </div>
          </div>

          <ViewOngoingProjectCardComponent
            project_milestones={project?.project_milestones_set}
            project_milestones_completed={project?.project_milestones_completed}
            status={project?.project_status}
            calculatePercentage={calculatePercentage}
          />
        </div>
      </div>
    </>
  );
}
