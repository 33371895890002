import React from "react";
import { BsTrash } from "react-icons/bs";
import {
  FaFileImage,
  FaFilePdf,
  FaFileArchive,
  FaFileAudio,
  FaFile,
} from "react-icons/fa";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFileAlt, faSpinner, faTrash } from '@fortawesome/free-solid-svg-icons'
import "./FileItem.scss";

const FileItem = ({ file, deleteFile }) => {
  const [icon, setIcon] = React.useState(null);
  React.useEffect(() => {
    // console.log("type", file.type);
    if (file.type.split("/")[0] === "image") {
      return setIcon(<FaFileImage />);
    }
    if (file.type.split("/")[0] === "audio") {
      return setIcon(<FaFileAudio />);
    }
    if (file.type.split("/")[0] === "application") {
      if (file.type.split("/")[1] === "pdf") {
        return setIcon(<FaFilePdf />);
      }
      if (file.type.split("/")[1] === "zip") {
        return setIcon(<FaFileArchive />);
      }
    }
    return setIcon(<FaFile />);
  }, [file]);
  return (
    <div className="FileItem px-0 pl-0">
      <li className="file-item" key={file.name}>
        {/* <FontAwesomeIcon icon={faFileAlt} /> */}
        {icon}
        <p>{file.name}</p>
        <div className="actions">
          {
            !file.isUploading && (
              <BsTrash onClick={() => deleteFile(file.name)} />
            )

            // <FontAwesomeIcon icon={faTrash}
            //     onClick={() => deleteFile(file.name)} />
          }
        </div>
      </li>
    </div>
  );
};

export default FileItem;
