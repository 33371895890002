import {
  Avatar,
  Button,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import React, { useContext, useState } from "react";
import { BsChevronDown, BsChevronExpand, BsSearch } from "react-icons/bs";
import { AiOutlineGlobal } from "react-icons/ai";
import "./SearchProjectPage.scss";
import categoryIcon from "../../assets/icons/bx_category.svg";
import { useNavigate } from "react-router-dom";
import { ProjectsContext } from "./../../context/ProjectsContext";

function SearchProjectPage() {
  const {
    listProjects,
    filterOpen,
    setFilterOpen,
    setSearchData,
    searchData,
    getProjects,
    handleResetSearchProject,
  } = useContext(ProjectsContext);
  const [filteredProjects, setFilteredProjects] = useState(listProjects);

  React.useEffect(() => {
    setFilteredProjects(() => listProjects);
  }, [listProjects]);

  const handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    const projects = listProjects?.data;
    setFilteredProjects({ loading: true, data: [] });
    const filtered = projects.filter((project) =>
      project.project_title.toLowerCase().includes(searchQuery)
    );
    // console.log(filtered);
    setFilteredProjects({ loading: false, data: filtered });
  };

  return (
    <div className='SearchProjectPage'>
      <div>
        <SearchComponent
          setFilterOpen={setFilterOpen}
          filterOpen={filterOpen}
          handleSearch={handleSearch}
        />
      </div>
      <div>
        {filterOpen ? (
          <FilterComponent
            setSearchData={setSearchData}
            searchData={searchData}
            getProjects={getProjects}
            handleResetSearchProject={handleResetSearchProject}
          />
        ) : // <SuggestionsScrollComponent />
        null}
      </div>

      <div className='d-flex justify-content-between align-items-center search-info'>
        <div>
          {listProjects?.data ? (
            <p className='leading-none'>
              {"Showing " + listProjects?.data?.length + " project(s)"}
            </p>
          ) : (
            <p className='leading-none'>{"No projects found"}</p>
          )}
          <sup className='leading-none'>Based on preferences</sup>
        </div>
        <div className='d-none gap-2 align-items-center'>
          <Menu isLazy>
            <MenuButton
              as={Button}
              className='dropdown'
              rightIcon={<BsChevronDown />}
              leftIcon={
                <svg
                  stroke='currentColor'
                  fill='currentColor'
                  strokeWidth='0'
                  viewBox='0 0 512 512'
                  height='1em'
                  width='1em'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M304 416h-64a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h64a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-128-64h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.37 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352zm256-192H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zm-64 128H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM496 32H240a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h256a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z'></path>
                </svg>
              }
            >
              Around You
            </MenuButton>
            <MenuList>
              {/* MenuItems are not rendered unless Menu is open */}
              <MenuItem>New Window</MenuItem>
              <MenuItem>Open Closed Tab</MenuItem>
              <MenuItem>Open File</MenuItem>
            </MenuList>
          </Menu>

          <button className='icon-btn'>
            <img src={categoryIcon} alt='' />
          </button>
        </div>
      </div>

      <div className='project-list-container'>
        {filteredProjects?.loading ? (
          <div className='mt-5 fs-3 fw-bolder text-muted'>Loading...</div>
        ) : (
          <>
            {filteredProjects?.data?.length < 1 ? (
              <div className='mt-5 fs-3 fw-bolder text-muted'>
                No Project Found
              </div>
            ) : (
              <>
                {filteredProjects?.data?.map((project, i) => (
                  <ProjectCardComponent project={project} key={i} />
                ))}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default SearchProjectPage;

function SearchComponent({ setFilterOpen, filterOpen, handleSearch }) {
  return (
    <div className='searchComponent'>
      {/* <div className=''>
        <Menu isLazy>
          <MenuButton
            as={Button}
            className='search-dropdown'
            rightIcon={<BsChevronDown />}
            leftIcon={
              <svg
                stroke='currentColor'
                fill='currentColor'
                strokeWidth='0'
                version='1.2'
                baseProfile='tiny'
                viewBox='0 0 24 24'
                height='1em'
                width='1em'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M17.657 5.304c-3.124-3.073-8.189-3.073-11.313 0-3.124 3.074-3.124 8.057 0 11.13l5.656 5.565 5.657-5.565c3.124-3.073 3.124-8.056 0-11.13zm-5.657 8.195c-.668 0-1.295-.26-1.768-.732-.975-.975-.975-2.561 0-3.536.472-.472 1.1-.732 1.768-.732s1.296.26 1.768.732c.975.975.975 2.562 0 3.536-.472.472-1.1.732-1.768.732z'></path>
              </svg>
            }
          >
            Around You
          </MenuButton>
          <MenuList>
             <MenuItem>New Window</MenuItem>
            <MenuItem>Open Closed Tab</MenuItem>
            <MenuItem>Open File</MenuItem>
          </MenuList>
        </Menu>
      </div> */}
      <Divider orientation='vertical' />
      <div className='flex flex-1'>
        <Input
          placeholder='Search by project title, owner or category'
          size='md'
          onChange={(e) => handleSearch(e)}
          className='w-full'
        />
      </div>

      {/* <Button
        leftIcon={<GoSettings />}
        className={`filter-button ${filterOpen ? "open" : ""}`}
        onClick={() => setFilterOpen(!filterOpen)}
      >
        <span className='max-lg:hidden'>FILTER</span>
      </Button> */}
      <Button leftIcon={<BsSearch />} className='find-button mr-2'>
        <span className='hidden sm:block'>Search</span>
      </Button>
    </div>
  );
}

function GoSettings(props) {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth={0}
      viewBox='0 0 16 16'
      height='1em'
      width='1em'
      {...props}
    >
      <path
        fillRule='evenodd'
        d='M4 7H3V2h1v5zm-1 7h1v-3H3v3zm5 0h1V8H8v6zm5 0h1v-2h-1v2zm1-12h-1v6h1V2zM9 2H8v2h1V2zM5 8H2c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1zm5-3H7c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1zm5 4h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1s-.45-1-1-1z'
      />
    </svg>
  );
}

function SuggestionsScrollComponent() {
  const [active, setActive] = useState(null);
  const Suggestions = [
    "Bungalows",
    "Sky scrapers",
    "Network Masts",
    "Road Construction",
  ];
  return (
    <div className='SuggestionsScrollComponent'>
      Suggestions{" "}
      <div className='Suggestion-container'>
        {Suggestions?.map((suggestion, i) => {
          return (
            <button
              key={i}
              className={active === i ? "active" : ""}
              onClick={() => {
                setActive(i);
              }}
            >
              {suggestion}
            </button>
          );
        })}
      </div>
    </div>
  );
}
function FilterComponent({
  setSearchData,
  searchData,
  getProjects,
  handleResetSearchProject,
}) {
  const handelChanges = (e) => {
    setSearchData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
        searching: true,
      };
    });
    // console.log(searchData);
  };
  return (
    <div className='SuggestionsScrollComponent'>
      {/* FilterComponent{" "} */}
      <div className='filter-form-container'>
        <label>
          <span className='label-name'>Amount</span>
          {/* <Input placeholder="Name" type="text" /> */}
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              // type={show ? "text" : "password"}
              placeholder='Amount'
              name='budget'
              onChange={(e) => handelChanges(e)}
            />
            <InputRightElement width='4.5rem'>
              <Button
                h='1.75rem'
                size='sm'
                onClick={() => {
                  getProjects(searchData);
                }}
              >
                search
              </Button>
            </InputRightElement>
          </InputGroup>
        </label>
        <label>
          <span className='label-name'>Location</span>
          {/* <Input placeholder="Name" type="text" /> */}
          <InputGroup size='md'>
            <InputLeftElement width='2.5rem'>
              <AiOutlineGlobal />
            </InputLeftElement>
            <Input
              pr='4.5rem'
              // type={show ? "text" : "password"}
              placeholder='Enter Location'
              name='location'
              onChange={(e) => handelChanges(e)}
            />
            <InputRightElement width='4.5rem'>
              <Button
                h='1.75rem'
                size='sm'
                onClick={() => {
                  getProjects(searchData);
                }}
              >
                search
              </Button>
            </InputRightElement>
          </InputGroup>
        </label>
        <label>
          <span className='label-name'>Project Type</span>
          <Menu isLazy>
            <MenuButton
              as={Button}
              className='filter-dropdown '
              rightIcon={<BsChevronExpand />}
              name='category'
              onChange={(e) => handelChanges(e)}
            >
              Bungalow
            </MenuButton>
            <MenuList>
              {/* MenuItems are not rendered unless Menu is open */}
              <MenuItem>New Window</MenuItem>
              <MenuItem>Open Closed Tab</MenuItem>
              <MenuItem>Open File</MenuItem>
            </MenuList>
          </Menu>
        </label>
        {/* <label className="ms-auto"> */}
        {/* <span>Name</span> */}
        <Button
          className='ms-auto mt-auto text-muted'
          leftIcon={
            <svg
              stroke='currentColor'
              fill='currentColor'
              strokeWidth='0'
              viewBox='0 0 20 20'
              height='1em'
              width='1em'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                d='M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z'
                clip-rule='evenodd'
              ></path>
            </svg>
          }
          colorScheme='teal'
          variant='solid'
          onClick={() => {
            handleResetSearchProject();
          }}
        >
          Reset
        </Button>
        {/* </label> */}
      </div>
    </div>
  );
}

export function ProjectCardComponent({ project }) {
  // React.useEffect(() => {
  //   // console.log(project);
  // }, []);

  const navigate = useNavigate();

  function cropText(text) {
    if (text.length > 100) {
      return `${text.slice(0, 150)} ...`;
    }
    return `${text} ...`;
  }

  function handelOpenProject() {
    navigate("/dashboard/search-project/" + project.project_id);
    // navigate("/projects/" + project.id)
  }
  return (
    <div className='ProjectCardComponent' onClick={() => handelOpenProject()}>
      <div className='top'>
        <div>
          <h3>{project?.project_title}</h3>
          <p>{project?.budget}</p>
        </div>
        <Avatar src='https://bit.ly/broken-link' />
      </div>
      <p>{cropText(project?.project_desc)}</p>
      <div className='down text-sm'>
        <button className=' text-xs'>{project?.category}</button>{" "}
        {project?.country}, {project?.state}
      </div>
    </div>
  );
}
