import React from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './ProgressBarComponent.scss';

function ProgressBarComponent({ percentage }) {
  return (
    <div className="ProgressBarComponent mt-4">
      <CircularProgressbarWithChildren
        value={percentage}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0.66,
          // rotation: 0.05,

          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'round',

          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,

          pathColor: `rgba(0, 8, 255, ${percentage / 100})`,
          trailColor: '#ffffff',
        })}
      >
        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
        <div className="text-gray-300 text-sm">
          <div className="text-3xl text-center">{percentage}%</div>
          <div className=" text-center">Complete</div>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}

export default ProgressBarComponent;
export function ProgressBarChart({ percentage }) {
  return (
    <div className="h-20 w-20 p-1">
      <CircularProgressbarWithChildren
        value={percentage}
        styles={buildStyles({
          rotation: 0.66,

          strokeLinecap: 'round',
          pathColor: '#000aff',
          pathTransitionDuration: 0.5,
        })}
      >
        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
        <h1 className="text-sm">{percentage + '%'}</h1>
      </CircularProgressbarWithChildren>
    </div>
  );
}
