import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  useContext,
} from "react";

const PushNotificationContext = createContext();

const PushNotificationProvider = ({ children }) => {
  //   function isPushNotificationSupported() {
  //     return "serviceWorker" in navigator && "PushManager" in window;
  //   }

  //   function registerServiceWorker() {
  //     return navigator.serviceWorker.register("/sw.js");
  //   }

  //   async function askUserPermission() {
  //     return await Notification.requestPermission();
  //   }

  //   let checkNotificationPermissions = async () => {
  //     const askResponse = await askUserPermission();

  //     console.log(askResponse);
  //     if (askResponse === true) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   };

  // async function createNotificationSubscription() {
  //   //wait for service worker installation to be ready
  //   const serviceWorker = await navigator.serviceWorker.ready;
  //   // subscribe and return the subscription
  //   return await serviceWorker.pushManager.subscribe({
  //     userVisibleOnly: true,
  //     applicationServerKey: pushServerPublicKey,
  //   });
  // }

  //   useEffect(() => {
  //     if (isPushNotificationSupported()) {
  //       registerServiceWorker();

  //       console.log(checkNotificationPermissions());
  //     }
  //   }, []);

  /**
   * checks if Push notification and service workers are supported by your browser
   */
  function isPushNotificationSupported() {
    return "serviceWorker" in navigator && "PushManager" in window;
  }

  /**
   * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
   */
  function initializePushNotifications() {
    // request user grant to show notification
    return Notification.requestPermission(function (result) {
      return result;
    });
  }
  /**
   * shows a notification
   */
  function sendNotification() {
    const img = "https://app.urbex.africa/static/media/logo2.249c778176f4f4b0e3df.png";
    const text = "Take a look at this brand new t-shirt!";
    const title = "New Product Available";
    const options = {
      body: text,
      // icon: "/images/jason-leung-HM6TMmevbZQ-unsplash.jpg",
      icon: "https://app.urbex.africa/static/media/logo2.249c778176f4f4b0e3df.png",
      vibrate: [200, 100, 200],
      tag: "new-product",
      image: img,
      badge: "https://app.urbex.africa/static/media/logo2.249c778176f4f4b0e3df.png",
      actions: [
        {
          action: "Detail",
          title: "View",
          icon: "https://app.urbex.africa/static/media/logo2.249c778176f4f4b0e3df.png",
        },
      ],
    };
    navigator.serviceWorker.ready.then(function (serviceWorker) {
      serviceWorker.showNotification(title, options);
    });
  }

  /**
   *
   */
  function registerServiceWorker() {
    navigator.serviceWorker.register("/sw.js").then(function (swRegistration) {
      //you can do something with the service wrker registration (swRegistration)
    });
  }

  return (
    <PushNotificationContext.Provider
      value={{
        isPushNotificationSupported,
        initializePushNotifications,
        registerServiceWorker,
        sendNotification,
      }}
    >
      {children}
    </PushNotificationContext.Provider>
  );
};
export { PushNotificationProvider, PushNotificationContext };
