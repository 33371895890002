import React, { createContext, useEffect, useState } from "react";

export const TostMessageContext = createContext();

export function TostMessageProvider({ children }) {
  const [tostMessage, setTostMessage] = useState({
    messageType: "",
    message: "",
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTostMessage({ messageType: "", message: "" });
    }, 6000);
    return () => clearTimeout(timer);
  }, [tostMessage]);

  return (
    <TostMessageContext.Provider value={{ tostMessage, setTostMessage }}>
      {children}
    </TostMessageContext.Provider>
  );
}
