// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import { messaging_token_endpoint } from "../config/variables";
import axios from "axios";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDPAaf7mQeHQqpYUNFUT_9k8ia6OaAgs4M",
  authDomain: "pmp2022-d5938.firebaseapp.com",
  projectId: "pmp2022-d5938",
  storageBucket: "pmp2022-d5938.appspot.com",
  messagingSenderId: "31463506976",
  appId: "1:31463506976:web:67ead9f6ed470ef0d9a845",
  measurementId: "G-7SK0KD9NRN",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);
// const analytics = getAnalytics(app);

export const generateToken = async (access, account_id) => {
  if (access && account_id) {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BHg7_b11R0fvqROmLa50EJ3B9MwJ8Rvyvk2dIO5AeiFAXsM7HiaRG3QX1Xm4HfJQbVw28833O4sux2IoL7BIKvM",
      });
      try {
        const response = await axios.post(messaging_token_endpoint, {
          access: access,
          account_id: account_id,
          token: token,
          os: "web",
        });
        const resData = await response.data;
        console.log({ permission, resData });
      } catch (e) {
        return e;
      }
    }
  }
};
