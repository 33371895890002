import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';

import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/logo2.png';

export function ChangePassword({ email }) {
  const { ChangePasswordFunction } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: '',
      new_password: '',
      confirm_new_password: '',
    },
  });

  const onSubmit = (values) => {
    // // console.log(values);
    setLoading(false);
    ChangePasswordFunction({ ...values, email });
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
      <div className="self-center mb-4">
        <img src={Logo} className="h-20" alt="logo" />
      </div>

      <input
        type="number"
        placeholder={'Enter code'}
        {...register('code', {
          required: true,
        })}
        className={clsx('input w-full bg-base-100 signup-input', {
          ['border border-danger']: errors.code,
        })}
      />
      <input
        type="password"
        placeholder={'Enter new password'}
        {...register('new_password', {
          required: true,
        })}
        className={clsx('input w-full bg-base-100 mt-3 signup-input', {
          ['border border-danger']: errors.new_password,
        })}
      />
      <input
        type="password"
        placeholder={'Confirm new password'}
        {...register('confirm_new_password', {
          required: true,
        })}
        className={clsx('input w-full bg-base-100 mt-3 signup-input', {
          ['border border-danger']: errors.confirm_new_password,
        })}
      />

      <div className="mt-10 join join-vertical lg:join-horizontal flex gap-5 justify-between">
        <div className="flex flex-col">
          <div className="font-bold lg:text-xl">
            Remember your password?
            <Link
              to={'/login'}
              className="text-primary fw-bolder cursor-pointer"
            >
              {' '}
              Login
            </Link>
          </div>
        </div>
        {loading ? (
          <ReactLoading type={'cylon'} color="#322a86" />
        ) : (
          <button
            disabled={loading}
            type="submit"
            className="bg-native-blue rounded-lg text-white p-3 px-5"
          >
            Verify
          </button>
        )}
      </div>
    </form>
  );
}
