import React from 'react';
import styled from 'styled-components';
import Imagedata from "../../assets/images/search.svg";

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // full height of the viewport
  text-align: center;
  background-color: #f0f4f8;
`;


const Image = styled.img`
  max-width: 250px; // adjust as necessary
  max-height: 250px; // adjust as necessary
  margin-bottom: 14px;
`;

const Title = styled.h1`
  color: #000;
  margin-top: 20px;
`;

const Subtitle = styled.p`
  color: #555;
  margin-bottom: 20px;
`;

const BackButton = styled.button`
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

// React component
const SearchQueryEmpty = () => {
  return (
    <Container>
      <Image src={Imagedata} alt='No Result Found' />
      <Title>No Result Found</Title>
      <Subtitle>There was no search result found for "boots". Try rephrasing your search.</Subtitle>
      <BackButton onClick={() => window.history.back()}>Back To Home</BackButton>
    </Container>
  );
}

export default SearchQueryEmpty;
