import clsx from 'clsx';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';
import {AuthContext} from '../../context/AuthContext';
import all_countries from '../../config/variables/constants';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

export function RegisterForm() {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [error, setError] = useState('');
  const [value, setValue] = useState('+234');

  const { RegistrationFunction } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      email: "",
      first_name: "",
      last_name: "",
      phone_number:"",
      country_code:"",
      password: "",
      confirmPassword: "",
      account_type: "client",
      title:""
    },
  });

  const onSubmit = (data) => {
    setError('');
    setLoading(true);
    data.phone_number = value+''+data.phone_number;
    if (data.password !== data.confirmPassword) {
      setError('Passwords must match');
      setLoading(false);
      return;
    }
    RegistrationFunction(data, setLoading, reset);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="">
      <p className="text-center">
        Create an Urbex PRO account
      </p>
      <select
        {...register('title', {
          required: 'Title is required'
        })}
        className={clsx('input w-full bg-base-100 mt-3 signup-input', {
          'border border-danger': errors.title,
        })}
      >
        <option value="">Select your title</option>
        <option value="Mr">Mr</option>
        <option value="Mrs">Mrs</option>
        <option value="Miss">Miss</option>
        <option value="Dr">Dr</option>
        <option value="Engr">Engr</option>
      </select>
      {errors.title && <p className="text-danger">{errors.title.message}</p>}
      <input
        type="text"
        placeholder={'Enter your first name'}
        {...register('first_name', {
          required: true,
        })}
        className={clsx('input w-full bg-base-100 mt-3 signup-input', {
          ['border border-danger']: errors.first_name,
        })}
      />

      <input
        type="text"
        placeholder={'Enter your last name'}
        {...register('last_name', {
          required: true,
        })}
        className={clsx('input w-full bg-base-100 mt-3 signup-input', {
          ['border border-danger']: errors.last_name,
        })}
      />


      <input
        type="text"
        placeholder={'Enter your email address'}
        {...register('email', {
          required: true,
        })}
        className={clsx('input w-full bg-base-100 mt-3 signup-input', {
          ['border border-danger']: errors.email,
        })}
      />
      <div className='flex flex-row  justify-between gap-2 mt-3' style={{alignItems:'center',position:'relative'}}>
      
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          defaultCountry="NG"
          value={value}
          onChange={setValue}/>

        <input
        type="text" id='phone_number'
        style={{paddingLeft:95}}
        placeholder={'Enter your phone number'}
        {...register('phone_number', {
          required: true,
        })}
        className={clsx('input w-[90%] bg-base-100 signup-input', {
          ['border border-danger']: errors.phone_number,
        })}
      />
      </div>
      <h6 className="mt-3">Please use a strong password</h6>

      <div className="join join-vertical lg:join-horizontal mt-4 flex gap-3 justify-between">
        <input
          type="password"
          placeholder="Enter password"
          autoComplete='off'
          {...register('password', {
            required: true,
          })}
          minLength={8}
          className={clsx('input w-full bg-base-100 signup-input', {
            ['border border-danger']: errors.password,
          })}
        />
        <input
          type="password"
          autoComplete='off'
          placeholder="Confirm password"
          {...register('confirmPassword', {
            required: true,
          })}
          minLength={8}
          className={clsx('input w-full bg-base-100 signup-input', {
            ['border border-danger']: errors.confirmPassword,
          })}
        />
      </div>

      {error && <p className="text-rose-500 mt-4 text-xl">{error}</p>}
      <div className="form-control mt-4">
        <label className="cursor-pointer flex items-center gap-x-4">
          <input
            type="checkbox"
            checked={checked}
            defaultChecked={false}
            onClick={() => setChecked(!checked)}
            className="checkbox checkbox-primary"
          />
          <span className="label-text">
            You AGREE to our{' '}
            <span className="text-primary cursor-pointer">
              terms and conditions
            </span>
          </span>
        </label>
      </div>
      <div className="flex flex-col mt-4">
        {loading ? (
          <ReactLoading type={'cylon'} color="#000aff" />
        ) : (
          <button
            disabled={loading || !checked}
            type="submit"
            className="px-10 py-2 text-white rounded-lg bg-native-blue"
          >
            Sign Up
          </button>
        )}
        <Link to={'/login'} className="btn btn-ghost text-sm">
          <p>Have an account? Sign In</p>
        </Link>
      </div>
    </form>
  );
}
