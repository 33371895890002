// import axios from "axios";
import React from "react";
import FileItem from "./../FileItem/FileItem";

const FileList = ({ files, removeFile }) => {
  const deleteFileHandler = (index) => {
    // axios.delete(`http://localhost:8080/upload?name=${_name}`)
    //     .then((res) => removeFile(_name))
    //     .catch((err) => console.error(err));
    removeFile(index);
  };
  React.useEffect(() => {
    // console.log("files", files);
  }, [files]);
  return (
    <ul className="file-list">
      {files &&
        files.map((file, i) => (
          <FileItem
            className="px-0 pl-0"
            key={i}
            file={file}
            deleteFile={() => deleteFileHandler(i)}
          />
        ))}
    </ul>
  );
};

export default FileList;
