import React, { useState, useContext } from "react";
import axios from 'axios';
import "./Modal.scss";
import imageOne from "../../assets/images/owner.png";
import imageTwo from "../../assets/images/contractor.jpg";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import { TostMessageContext } from "../../context/TostMessage";
import { IoMdArrowRoundBack,IoMdClose } from "react-icons/io";
import {
    useNavigate,
} from "react-router-dom";
import {GiArtificialIntelligence} from 'react-icons/gi';
import ReactLoading from 'react-loading';

const SuccessModal = ({ isOpen, onClose, setIsSuccessModalOpen }) => {
    // Always call useState at the top level
    const [showModal, setShowModal] = useState(true);
    const [cookies] = useCookies();
    const navigate = useNavigate();
    const { setTostMessage } = React.useContext(TostMessageContext);
    const { isLoggedIn, isLoading} = useContext(AuthContext);
    const { userProfile, forceLogOutFunction, getUserProfileFunction } = useContext(AuthContext);

    // State to store form data
    
    if (!isOpen) return null;



    return (
        <div className="backdrop">
            {showModal && (
                <div className="modal_container justify-start">
                    <div className="w-full flex flex-column items-end">
                        <button onClick={() => {setIsSuccessModalOpen(false);navigate("/dashboard/ongoing-projects"); }} className="h-10 w-10 flex items-center justify-center rounded-full bg-[#ff0000]">
                            <IoMdClose size={20} color="#ffffff" />
                        </button>
                    </div>
                    <div className="text-left mb-0">
                        <h3 className="mb-0 text-[25px] font-bold">Project Created Successfully!</h3>
                        {/* <h3 className="mb-0 text-[17px] font-semibold">You are almost there..</h3> */}
                    </div>
                    <div className="w-[100%] gap-y-2 flex flex-column mx-auto my-3 items-center">
                        {/* <GiArtificialIntelligence size={100} color="#000aff" /> */}
                        <ReactLoading type={'balls'} color="#000aff" />
                        <b className="text-native-blue">AI at Work</b>
                    </div>
                    <div className="w-[100%] gap-y-2 flex flex-column mx-auto my-3 items-center">
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <a className="w-[90%] text-center font-semibold text-[15px]">We are currently pairing you with the most suitable project manager for this project.</a>
                        </div>
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <a className="w-[90%] text-center font-semibold text-[15px]">They will reach out once assigned.</a>
                        </div>
                    </div>
                    <button onClick={() => {window.open('https://wa.link/l8wtc0', '_blank');setIsSuccessModalOpen(false);navigate("/dashboard/ongoing-projects");}} className="bg-native-blue continue w-4/5 mx-auto !rounded-full" type="button">
                        Reachout, Instead
                    </button>
                </div>
            )}
        </div>
    );
};

export default SuccessModal;
