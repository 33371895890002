import { Button } from "@chakra-ui/react";
import React from "react";
import { VerificationContext } from "../../context/VerificationContext";

function StepperIndicator({ onClose }) {
  const {
    isContractor,
    loading,
    activeStep,
    setActiveStep,
    stepsContents,
    handelPersonalInformationVerification,
    handelCorporateInformationVerification,
  } = React.useContext(VerificationContext);
  return (
    <>
      <Button
        className='next-button mt-3 mb-3 mx-auto'
        type='submit'
        isLoading={loading}
        onClick={() => {
          if (isContractor) {
            if (activeStep === 0) {
              handelPersonalInformationVerification();
              return;
            }
            if (activeStep === 1) {
              handelCorporateInformationVerification();
              return;
            }
            if (activeStep === 2) {
              console.log("first:");
              onClose();
              return;
            }
            return;
          }
        }}
      >
        {loading ? "Loading...." : "Next"}
      </Button>
      <div className='indicator-container'>
        {/* {// console.log({ steps })} */}
        {stepsContents?.map((step, i) => (
          <div
            key={i}
            className={`indicator ${activeStep === i ? "active" : ""}`}
            onClick={() => setActiveStep(i)}
          ></div>
        ))}
      </div>
    </>
  );
}

export default StepperIndicator;
