import React from 'react';
import './AuthPage.scss';
import { RegisterForm } from '../../components/FormsComponent/RegisterForm';
import bgImage from '../../assets/images/auth-bg.svg';
import Logo from '../../assets/images/logo2.png';

function RegisterPage() {
  return (
    <div className="relative max-h-screen h-full">
      <img
        src={bgImage}
        alt="image"
        className="absolute top-0 right-0 left-0 bottom-0  object-cover w-full opacity-50"
      />
      <div className="absolute z-10 flex top-0 right-0 left-0 p-2 bottom-0 object-cover w-full">
        <div className="bg-[#ffffff] shadow-2xl rounded-xl p-4 max-w-[470px] flex flex-col m-auto">
          <div className="self-center mb-2">
            <img src={Logo} className="h-20" alt="logo" />
          </div>
          <RegisterForm />
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
