import * as yup from "yup";

const passwordRules = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/


export const emailForPasswordResetValidationSchema = yup.object({
  email: yup
    .string("Enter your Email Address")
    .email("Enter a valid Email Address")
    .required("Email is required")
});
export const passwordResetValidationSchema = yup.object({
  code: yup
    .string("Enter your OTP")
    .matches(/^[0-9]+$/, "OTP Must be only digits")
    .max(4, 'OTP Must be exactly 4 digits').required("OTP is required"),
  new_password: yup.string("Enter your password")
    // .matches(passwordRules, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
    .required("Password is required"),
  confirm_new_password: yup.string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match').required("Confirm password is required")

});
export const otpValidationSchema = yup.object({
  code: yup
    .string("Enter your OTP")
    .matches(/^[0-9]+$/, "OTP Must be only digits")
    .max(4, 'OTP Must be exactly 4 digits').required("OTP is required")
});
export const loginValidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password")
    // .matches(passwordRules, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
    .required("Password is required"),
});

export const registerValidationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  first_name: yup
    .string("Enter your First name")
    // .min(4, "First name should be of minimum 4 characters length")
    .required("First name is required"),
  last_name: yup
    .string("Enter your Last name")
    // .min(4, "Last name should be of minimum 4 characters length")
    .required("Last name is required"),
  account_type: yup
    .string()
    .oneOf(["client", "contractor"], "Select an account type")
    .required("Select an account type"),
  password: yup
    .string("Enter your password")
    // .matches(passwordRules, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
    .required("Password is required"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});
