import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Logo from '../../assets/images/logo2.png';

import { useForm } from 'react-hook-form';
import ReactLoading from 'react-loading';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

export function PasswordReset({ setKeepEmail }) {
  const { SendOtpForPasswordRestFunction } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const onSubmit = (values) => {
    // // console.log(values);
    setLoading(true);
    setKeepEmail(values.email);
    SendOtpForPasswordRestFunction(values, setLoading);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
      <div className="self-center mb-4">
        <img src={Logo} className="h-20" alt="logo" />
      </div>
      <input
        type="email"
        placeholder={'Enter email'}
        {...register('email', {
          required: true,
        })}
        className={clsx('input w-full bg-base-100 signup-input', {
          ['border border-danger']: errors.email,
        })}
      />
      <div className="mt-10 join join-vertical lg:join-horizontal flex gap-5 justify-between">
        <div className="flex flex-col">
          <div className="font-bold lg:text-xl">
            Remember your password?
            <Link
              to={'/login'}
              className="text-primary fw-bolder cursor-pointer"
            >
              {' '}
              Login
            </Link>
          </div>
        </div>
        {loading ? (
          <ReactLoading type={'cylon'} color="#322a86" />
        ) : (
          <button
            disabled={loading}
            type="submit"
            className="bg-native-blue rounded-lg text-white p-3 px-5"
          >
            Verify
          </button>
        )}
      </div>
    </form>
  );
}
