import React from "react";

import {
  Box,
  FormLabel,
  Input,
  Select,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { AuthContext } from "../../context/AuthContext";
import { TostMessageContext } from "../../context/TostMessage";
import { VerificationContext } from "../../context/VerificationContext";

function Step2FormComponent() {
  const firstField = React.useRef();
  const { setTostMessage } = React.useContext(TostMessageContext);
  const { userProfile } = React.useContext(AuthContext);

  const { verifyCorporateFormData, setVerifyCorporateFormData } =
    React.useContext(VerificationContext);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file.type.match(/image\/*/) || !file.type === "application/pdf") {
      // alert("Please select a valid file type (image/pdf)");
      setTostMessage({
        messageType: "error",
        message: "Please select a valid file type (image/pdf)",
      });
    }
    if (file.size > 5242880) {
      // 5MB in bytes
      setTostMessage({
        messageType: "error",
        message: "File size should be less than 5MB",
      });
    } else {
      setVerifyCorporateFormData({
        ...verifyCorporateFormData,
        [event.target.name]: file,
      });
    }
  };
  const handleChange = (event) => {
    setVerifyCorporateFormData({
      ...verifyCorporateFormData,
      [event.target.name]: event.target.value,
    });
  };
  const handelSubmits = (e) => {
    e.preventDefault();

    // verifyContractor(formData);
  };
  return (
    <form
      onSubmit={(e) => {
        handelSubmits(e);
      }}
      encType='multipart/form-data'
      className='row'
    >
      <div className='col-12 mt-3'>
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='corporate_id_file'>
            Upload COREN ID (max 10mb)
          </FormLabel>
          <input
            type='file'
            id='corporate_id_file'
            name='corporate_id_file'
            onChange={(e) => handleFileSelect(e)}
            style={{ display: "none" }}
            accept='image/*,application/pdf'
          />
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              placeholder={
                verifyCorporateFormData.corporate_id_file
                  ? "Selected"
                  : "Select a file"
              }
              readOnly={true}
            />
            <InputRightElement width='4.5rem'>
              <FormLabel
                style={{ fontSize: 13 }}
                className='file-select-label-button'
                // h="1.75rem"
                size={"sm"}
                htmlFor='corporate_id_file'
              >
                {verifyCorporateFormData.corporate_id_file
                  ? "Selected"
                  : "Upload"}
              </FormLabel>
            </InputRightElement>
          </InputGroup>
          {/* <sup>Utlity should not be older than 3 months</sup> */}
        </Box>
      </div>
      <div className='col-12 mt-3'>
        {" "}
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='corporate_id_number'>
            Enter COREN ID number
          </FormLabel>
          <Input
            ref={firstField}
            id='corporate_id_number'
            name='corporate_id_number'
            onChange={(e) => handleChange(e)}
            // {/* // placeholder="Please enter user name" */}
          />
        </Box>
      </div>
      <div className='col-md-6 mt-3'>
        {" "}
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='country'>
            Country
          </FormLabel>
          <Select
            id='country'
            defaultValue=' '
            name='country'
            onChange={(e) => handleChange(e)}
          >
            <option value=''></option>
            <option value='nigeria'>Nigeria</option>
            <option value='ghana'>Ghana</option>
          </Select>
        </Box>
      </div>
      <div className='col-md-6 mt-3'>
        {" "}
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='state'>
            State
          </FormLabel>
          <Select
            id='state'
            defaultValue=' '
            name='state'
            onChange={(e) => handleChange(e)}
          >
            <option value=''></option>
            <option value='Abuja'>Abuja</option>
            <option value='Lagos'>Lagos</option>
            <option value='Akara'>Akara</option>
          </Select>
        </Box>
      </div>
      <div className='col-12 mt-3'>
        {" "}
        <Box>
          <FormLabel style={{ fontSize: 13 }} htmlFor='corporate_id_file'>
            Choose profile picture
          </FormLabel>
          <input
            type='file'
            id='display_picture'
            name='display_picture'
            onChange={(e) => handleFileSelect(e)}
            style={{ display: "none" }}
            accept='image/*,application/pdf'
          />
          <InputGroup size='md'>
            <Input
              pr='4.5rem'
              placeholder={
                verifyCorporateFormData.display_picture
                  ? "Selected"
                  : "Select a file"
              }
              readOnly={true}
            />
            <InputRightElement width='4.5rem'>
              <FormLabel
                style={{ fontSize: 13 }}
                className='file-select-label-button'
                // h="1.75rem"
                size={"sm"}
                htmlFor='display_picture'
              >
                {verifyCorporateFormData.display_picture
                  ? "Selected"
                  : "Upload"}
              </FormLabel>
            </InputRightElement>
          </InputGroup>
          {/* <sup>Utlity should not be older than 3 months</sup> */}
        </Box>
      </div>
      {/* <button
        className="next-button mt-3 mb-3 mx-auto"
        type="submit"
        disabled={loading}
      >
        {loading ? "Loading" : "Next"}
      </button> */}
      {/* <Button
        isDisabled={formLoading}
        className="next-button mt-3 mb-3 mx-auto"
        type="submit"
      >
        {formLoading ? "Loading" : "Next"}
      </Button> */}
    </form>
  );
}

export default Step2FormComponent;
