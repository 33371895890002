import React, { useState } from "react";
import { FilterScrollComponent } from "../OngoingProjectsPage/OngoingProjectsPage";
import { Icon, Skeleton } from "@chakra-ui/react";
import "./ApplicationPage.scss";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Link, useNavigate} from "react-router-dom";
import { project_applications_end_point, project_request_end_point, accept_project_request } from "../../config/variables";
import { ProgressBarChart } from "./../../components/ProgressBarComponent/ProgressBarComponent";
import { BsThreeDots } from "react-icons/bs";
import project_icon from "../../assets/icons/project-icon.svg";
import { LuConstruction } from "react-icons/lu";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { TostMessageContext } from "../../context/TostMessage";
import { useContext } from "react";
import { FaRegEye, FaCheck } from "react-icons/fa";
import { TiCancel } from "react-icons/ti";

const filterArray = ["All", "Pending", "Interview", "Accepted", "Rejected"];

function ApplicationPage() {
  const [filterState, setFilterState] = useState("All");
  const [loading, setLoading] = useState(true);
  const [applications, setApplications] = React.useState(null);
  const [requests, setRequests] = React.useState(null);
  const navigate = useNavigate();
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();
  const {setTostMessage} = useContext(TostMessageContext)
  async function getApplications() {
    const data = {
      user: {
        account_id: userProfile?.account_id,
      },
      data: {
        records: 10,
        offset: 0,
      },
    };
    try {
      const response = await axios.post(project_applications_end_point, data, {
        headers: {
          access: cookies.urbexUserToken,
        },
      });
      // return response.data;
      setApplications(response.data.bids);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }

    const data2 = {
      user: {
        account_id: userProfile?.account_id,
      },
      data: {
        records: 10,
        offset: 0,
      },
    };
    try {
      const response = await axios.post(project_request_end_point, data2, {
        headers: {
          access: cookies.urbexUserToken,
        },
      });
      // return response.data;
      setRequests(response.data.requests);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  }

  async function acceptRequest(project_id, action) {
    const data = {
      user: {
        account_id: userProfile?.account_id,
      },
      data: {
        project_id: project_id,
        action: action
      },
    };
    try {
      const response = await axios.post(accept_project_request, data, {
        headers: {
          access: cookies.urbexUserToken,
        },
      });
      // return response.data;
      // setApplications(response.data.bids);
      if (response.data.status) {
        setTostMessage({
          messageType: "success",
          message: response.data.response,
        })
        return getApplications();
      } else {
        setTostMessage({
          messageType: "error",
          message: response.data.response,
        })
      }
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getApplications();
  }, []);

  React.useEffect(() => {
    getApplications();
  }, [filterState]);

  return (
    <div className='ApplicationPage'>
      <div className='text-start w-full mt-4 text-lg font-bold'>Bids Submitted</div>
      <div className='filter-container flex gap-x-2 mt-2 overflow-x-auto'>
        {filterArray?.map((item, i) => (
          <button
            key={i}
            className={`px-6 py-2 rounded-full border ${
              item === filterState
                ? "active text-lg bg-gradient-to-tr from-native-blue to-pink-500 text-white"
                : "text-lg bg-white"
            }`}
            onClick={() => {
              setFilterState(item);
            }}
          >
            {item}
          </button>
        ))}
      </div>

      {loading ? (
        <>
          {Array.from({ length: 3 }).map((item, index) => (
            <div className='bg-white rounded-lg py-10 w-full my-3 animate-pulse'></div>
          ))}
        </>
      ) : (
        <>
          {applications ? (
            <>
              {applications
                ?.filter((item) => {
                  if (filterState === "All") {
                    return true;
                  } else {
                    return item.status === filterState;
                  }
                })
                .map((bid, i) => {
                  if (filterState === "All" && i % 2 !== 0) {
                    return null;
                  } else {
                    return <ApplicationCardComponent bid={bid} key={i} />;
                  }
                })}
              {applications?.filter((item) => item.status === filterState)
                .length === 0 &&
                filterState !== "All" && (
                  <div className='mt-4'>No data available for filter</div>
                )}
              {applications?.length === 0 && filterState === "All" && (
                <div>No data available</div>
              )}
            </>
          ) : (
            <div>No data available</div>
          )}
        </>
      )}

      <div className='text-start w-full mt-4 text-lg font-bold'>Project Requests</div>
      {loading ? (
        <>
          {Array.from({ length: 3 }).map((item, index) => (
            <div className='bg-white rounded-lg py-10 w-full my-3 animate-pulse'></div>
          ))}
        </>
      ) : (
        <>
          {requests ? (
            <>
              {requests?.map((bid, i) => {
                  return <ProjectRequestCardComponent navigate={navigate} acceptRequest={acceptRequest}  bid={bid} key={i} />;
                })}
              {requests?.length === 0 && (
                <div>No project requests available</div>
              )}
            </>
          ) : (
            <div>No project requests available</div>
          )}
        </>
      )}
    </div>
  );
}

export default ApplicationPage;

function ApplicationCardComponent({ bid }) {
  return (
    <div className='projectCard flex p-3 md:p-4 lg:p-8 justify-between items-center h-auto md:h-28 lg:h-28 bg-white rounded-2xl my-3'>
      <div className="flex flex-row gap-x-4 md:gap-x-10 w-auto">
        <div className='hidden md:block lg:block'>
          {/* <img src={project_icon} alt='Project Icon' /> */}
          <LuConstruction size={40} color={'#000aff'} />
        </div>
        <div className='w-auto flex flex-column'>
          <h2 className='font-bold text-sm md:text-lg leading-tight'>
            {bid?.project_title}
          </h2>
          <span className='text-xs md:text-sm'>PROJECT OWNER: {bid?.about_client?.client_name}</span>
          {/* <p className='!leading-3'>
            Planned Start Date:{" "}
            {bid?.planned_start_date == ""
              ? "Not available"
              : bid?.planned_start_date}
          </p> */}
        </div>
      </div>
      <div className='badge-container'>
        <div className={`badge ${bid?.status}`}>
          {bid?.status === "Accepted" ? "Bid Accepted" : bid?.status}
          {bid?.status === "Accepted" ? <MdOutlineArrowForwardIos size={17} /> : null}
        </div>
      </div>
      {/* <div className='icon-container'>
        {bid?.status === "Accepted" ? <Icon as={BsThreeDots} /> : null}
      </div> */}
    </div>
  );
}

function ProjectRequestCardComponent({ bid, acceptRequest, navigate }) {
  function calculatePercentage(total, completed) {
    const percentage = (completed / total) * 100 || 0;
    return Math.round(percentage);
  }
  return (
    <div
      className='projectCard flex p-3 md:p-4 lg:p-8 justify-between items-center h-auto md:h-28 lg:h-28 bg-white rounded-2xl my-3'
    >
      {/* <div className='ApplicationCardComponent'>
        <div>
          <h1>{bid?.project_title}</h1>
          <p className='!leading-3'>{bid?.project_address}</p>
          <p className='!leading-3'>
            Planned Start Date:{" "}
            {bid?.planned_start_date == ""
              ? "Not available"
              : bid?.planned_start_date}
          </p>
        </div>
        <div className='badge-container'>
          <div className={`badge ${bid?.status}`}>
            {bid?.status === "Accepted" ? "Approved" : bid?.status}
          </div>
        </div>
        <div className='icon-container'>
          <Icon as={BsThreeDots} />
        </div>
      </div> */}
      <div className="flex flex-row gap-x-4 md:gap-x-10">
        <div className='hidden md:block lg:block'>
          {/* <img src={project_icon} alt='Project Icon' /> */}
          <LuConstruction size={40} color={'#000aff'} />
        </div>
        <div className='w-auto flex flex-column'>
          <h2 className='font-bold text-sm md:text-lg leading-tight'>
            {bid?.project_title}
          </h2>
          <span className='text-xs md:text-sm'>PROJECT OWNER: {bid?.about_client?.client_name}</span>
        </div>
      </div>
      {/* <div>
        <ProgressBarChart
          percentage={calculatePercentage(
            bid?.project_status?.project_milestones || 0,
            bid?.project_status?.project_milestones_completed || 0
          )}
        />
      </div> */}
      <div className='hidden sm:flex flex-row gap-x-2'>
          <button
            className={`px-6 py-2 rounded-full border-none text-lg text-white font-bold bg-[#000aff] mx-5`}
            onClick={() => {
              navigate(`/dashboard/ongoing-projects/${bid?.project_id}`);
            }}
          >
            View
          </button>
          <button
            onClick={() => acceptRequest(bid?.project_id, 'accept')}
            className={`px-6 py-2 rounded-full border-none text-lg text-white font-bold bg-[#00b47a]`}
          >
            Accept
          </button>
          <button
            onClick={() => acceptRequest(bid?.project_id, 'delete')}
            className={`px-6 py-2 rounded-full border-none text-lg text-white font-bold bg-[#d40000]`}
          >
            Delete
          </button>
      </div>
      <div className='flex sm:hidden flex-column gap-y-1'>
          <button
            className={`px-3 py-1 rounded-full border-none text-md text-white font-bold bg-[#000aff] my-2`}
            onClick={() => {
              navigate(`/dashboard/ongoing-projects/${bid?.project_id}`);
            }}
          >
            <FaRegEye size={10} />
          </button>
          <button
            onClick={() => acceptRequest(bid?.project_id, 'accept')}
            className={`px-3 py-1 rounded-full border-none text-md text-white font-bold bg-[#00b47a]`}
          >
            <FaCheck size={10} />
          </button>
          <button
            onClick={() => acceptRequest(bid?.project_id, 'delete')}
            className={`px-3 py-1 rounded-full border-none text-md text-white font-bold bg-[#d40000]`}
          >
            <TiCancel size={12} />
          </button>
      </div>
    </div>
  );
}
