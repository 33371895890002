import React, { useContext } from 'react';
import './AccountSetUpCard.scss';
import designImage from '../../assets/images/box-design.svg';
import { BsArrowRightShort } from 'react-icons/bs';

import DrawerComponent from './DrawerComponent';
import HorizontalNonLinearStepper from './HorizontalNonLinearStepper';
import { VerificationContext } from '../../context/VerificationContext';

function AccountSetUpCard({ profileName }) {
  const { activeStep, stepsContents } = useContext(VerificationContext);

  return (
    <DrawerComponent>
      <div className="hover:bg-white p-3 mb-4 rounded-xl border">
        {/* <img src={designImage} alt="box-design" className="box-design-image" /> */}
        <div className="d-flex justify-content-between">Hi, {profileName}</div>
        <h2 className="cursor-pointer">
          Finish your account setup ({activeStep}/2)
        </h2>

        <div className="stepper-container">
          <HorizontalNonLinearStepper stepsContents={stepsContents} />
        </div>
      </div>
    </DrawerComponent>
  );
}

export default AccountSetUpCard;
