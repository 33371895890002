import React, { useCallback, useContext, useEffect, useState } from "react";
import "./ProjectPage.scss";
import coverImag from "../../assets/images/profile-card-cover-image.png";
import greenTick from "../../assets/icons/green-tick.svg";
import galleryImage from "../../assets/images/gallery-image.png";
import { Avatar, Button, Divider, Icon } from "@chakra-ui/react";
import { BsHeart } from "react-icons/bs";
import person_icon from "../../assets/icons/person-icon.svg";
import time_icon from "../../assets/icons/time-icon.svg";
import review_icon from "../../assets/icons/review-icon.svg";
import { ProjectsContext } from "./../../context/ProjectsContext";
import { useNavigate, useParams } from "react-router-dom";
import {
  client_profile_end_point,
  select_project_by_id_end_point,
} from "./../../config/variables/index";
import axios from "axios";
import { useCookies } from "react-cookie";
import { MainDrawerComponent } from "../../components/AccountSetUpCard/DrawerComponent";
import { downloadFile } from "../../lib";
import { AuthContext } from "../../context/AuthContext";

function ProjectPage() {
  const { id } = useParams();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const { getProject, getClentProfile, listProjects, project, setProject } =
    useContext(ProjectsContext);
  const { userProfile } = useContext(AuthContext);

  // const [project, setProject] = useState({
  //   loading: true,
  //   data: {},
  // });
  const [client_profile, setClient_profile] = useState({
    loading: true,
    data: {},
  });

  useEffect(() => {
    getProject(id);
    return () => {
      if (project.data) {
        setProject({
          loading: false,
          data: null,
        });
      }
    };
  }, [id]);

  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };
  async function showProject() {
    // const project = await getProject(id);
    // console.log("one project", project);
    // if (project !== undefined ) {
    //   setProject({ loading: false, data: project });
    // }
    // console.log("function", listProjects);
    if (listProjects?.data?.length === 0 || listProjects.data === undefined) {
      // getProjects();
      navigate("/dashboard/search-project");
    }
    // const selectedProject = listProjects?.data?.filter((project) => {
    //   return project?.project_id === id;
    // });
    // if (selectedProject?.length > 0) {
    //   const project = selectedProject[0];
    //   setProject({ loading: false, data: project });
    // } else {
    //   // Handle case when project is not found
    // }
  }

  // useEffect(() => {
  //   if (project.data?.client_id) {
  //     saveClient_profile();
  //   }
  // }, [project]);
  async function saveClient_profile() {
    // const profile = await getClentProfile(project.data.client_id);
    // // // console.log("profile", profile);
    // setClient_profile({ loading: false, data: profile });
  }

  return (
    <div className='ProjectPage pt-4'>
      <div className='px-4'>
        {project.loading ? (
          <div className='Loading_screen'>Loading...</div>
        ) : (
          <div className='flex flex-col xl:grid grid-cols-3 gap-4'>
            <div className='col-span-1'>
              <div className='profile-card pb-4 rounded-3xl'>
                <div className='profile-image-container'>
                  <Avatar src={project.data?.client_display_picture} />
                </div>
                <h3>About the client</h3>
                <p>Joined {project?.data?.about_client?.time_joined}</p>

                <Button> {project?.data?.about_client?.kyc_stage}</Button>

                <div className='more-info-container'>
                  <div>
                    <div className='icon-container'>
                      {/* <Icon> */}
                      <img src={time_icon} />
                      {/* </Icon> */}
                    </div>
                    {project?.data?.about_client?.project_count} Project(s)
                  </div>
                  <div>
                    <div className='icon-container'>
                      <img src={review_icon} />
                    </div>
                    No review available
                  </div>
                </div>
              </div>
              <div className='bg-white rounded-3xl p-4 mt-4'>
                <h3 className='mb-2'>Project Files</h3>

                {project?.data?.project_documents.map((item, index) => (
                  <div
                    key={index}
                    className='flex items-center justify-between mt-3'
                  >
                    <div className='font-bold text-sm'>{item.file}</div>
                    <button
                      onClick={() => downloadFile(item.file_uri)}
                      className='bg-native-blue text-sm rounded-full text-white px-4 py-2'
                    >
                      Download
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className='col-span-2 '>
              <div className='project-info-card rounded-3xl'>
                <div className='top'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <h3>{project.data?.project_title}</h3>
                      <p>
                        {project.data?.site_address}, {project.data?.state}
                      </p>
                    </div>
                    <div className='d-flex gap-2'>
                      <MainDrawerComponent
                        open={6}
                        card={{ sub: "Details about the project" }}
                        project={project}
                      >
                        <Button>Apply now</Button>
                      </MainDrawerComponent>
                      <div className='icon-container'>
                        <BsHeart />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center gap-2 justify-content-start'>
                    <span className='price'>{project?.data?.budget}</span>{" "}
                    <Divider orientation='vertical' />
                    <p className='date m-0'>{project?.data?.date_created}</p>
                  </div>
                </div>
                <Divider className='mt-3' />
                <div className='d-flex align-items-center gap-2 justify-content-between info-grade'>
                  <div className='info-card'>
                    <div className='icon'>
                      <svg
                        width='23'
                        height='24'
                        viewBox='0 0 23 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M8.75 0.75L1.25 0.75C0.918479 0.75 0.600537 0.881696 0.366116 1.11612C0.131696 1.35054 0 1.66848 0 2L0 9.5C0 9.83152 0.131696 10.1495 0.366116 10.3839C0.600537 10.6183 0.918479 10.75 1.25 10.75H8.75C9.08152 10.75 9.39946 10.6183 9.63388 10.3839C9.8683 10.1495 10 9.83152 10 9.5V2C10 1.66848 9.8683 1.35054 9.63388 1.11612C9.39946 0.881696 9.08152 0.75 8.75 0.75ZM7.5 8.25H2.5L2.5 3.25L7.5 3.25V8.25ZM21.25 13.25L13.75 13.25C13.4185 13.25 13.1005 13.3817 12.8661 13.6161C12.6317 13.8505 12.5 14.1685 12.5 14.5L12.5 22C12.5 22.3315 12.6317 22.6495 12.8661 22.8839C13.1005 23.1183 13.4185 23.25 13.75 23.25H21.25C21.5815 23.25 21.8995 23.1183 22.1339 22.8839C22.3683 22.6495 22.5 22.3315 22.5 22V14.5C22.5 14.1685 22.3683 13.8505 22.1339 13.6161C21.8995 13.3817 21.5815 13.25 21.25 13.25ZM20 20.75H15L15 15.75L20 15.75V20.75ZM17.5 0.75C14.7425 0.75 12.5 2.9925 12.5 5.75C12.5 8.5075 14.7425 10.75 17.5 10.75C20.2575 10.75 22.5 8.5075 22.5 5.75C22.5 2.9925 20.2575 0.75 17.5 0.75ZM17.5 8.25C16.1213 8.25 15 7.12875 15 5.75C15 4.37125 16.1213 3.25 17.5 3.25C18.8787 3.25 20 4.37125 20 5.75C20 7.12875 18.8787 8.25 17.5 8.25ZM5 13.25C2.2425 13.25 0 15.4925 0 18.25C0 21.0075 2.2425 23.25 5 23.25C7.7575 23.25 10 21.0075 10 18.25C10 15.4925 7.7575 13.25 5 13.25ZM5 20.75C3.62125 20.75 2.5 19.6287 2.5 18.25C2.5 16.8713 3.62125 15.75 5 15.75C6.37875 15.75 7.5 16.8713 7.5 18.25C7.5 19.6287 6.37875 20.75 5 20.75Z'
                          fill='#8C8CFF'
                        />
                      </svg>
                    </div>{" "}
                    <div>
                      {" "}
                      <span>Project Category</span> <br />
                      <b>{project?.data?.category}</b>
                    </div>
                  </div>
                  <div className='info-card'>
                    <div className='icon'>
                      <svg
                        width='26'
                        height='26'
                        viewBox='0 0 26 26'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M21.5 5.5L4 5.5C2.61929 5.5 1.5 6.61929 1.5 8L1.5 21.75C1.5 23.1307 2.61929 24.25 4 24.25H21.5C22.8807 24.25 24 23.1307 24 21.75V8C24 6.61929 22.8807 5.5 21.5 5.5Z'
                          stroke='#BABAFA'
                          stroke-width='2.5'
                        />
                        <path
                          d='M1.5 10.5C1.5 8.1425 1.5 6.965 2.2325 6.2325C2.965 5.5 4.1425 5.5 6.5 5.5L19 5.5C21.3575 5.5 22.535 5.5 23.2675 6.2325C24 6.965 24 8.1425 24 10.5H1.5Z'
                          fill='#8C8CFF'
                        />
                        <path
                          d='M6.5 1.75V5.5M19 1.75V5.5'
                          stroke='#BABAFA'
                          stroke-width='2.5'
                          stroke-linecap='round'
                        />
                        <path
                          d='M10.875 13H7.125C6.77982 13 6.5 13.2798 6.5 13.625V14.875C6.5 15.2202 6.77982 15.5 7.125 15.5H10.875C11.2202 15.5 11.5 15.2202 11.5 14.875V13.625C11.5 13.2798 11.2202 13 10.875 13Z'
                          fill='#8C8CFF'
                        />
                        <path
                          d='M10.875 18H7.125C6.77982 18 6.5 18.2798 6.5 18.625V19.875C6.5 20.2202 6.77982 20.5 7.125 20.5H10.875C11.2202 20.5 11.5 20.2202 11.5 19.875V18.625C11.5 18.2798 11.2202 18 10.875 18Z'
                          fill='#8C8CFF'
                        />
                        <path
                          d='M18.375 13H14.625C14.2798 13 14 13.2798 14 13.625V14.875C14 15.2202 14.2798 15.5 14.625 15.5H18.375C18.7202 15.5 19 15.2202 19 14.875V13.625C19 13.2798 18.7202 13 18.375 13Z'
                          fill='#8C8CFF'
                        />
                        <path
                          d='M18.375 18H14.625C14.2798 18 14 18.2798 14 18.625V19.875C14 20.2202 14.2798 20.5 14.625 20.5H18.375C18.7202 20.5 19 20.2202 19 19.875V18.625C19 18.2798 18.7202 18 18.375 18Z'
                          fill='#8C8CFF'
                        />
                      </svg>
                    </div>{" "}
                    <div>
                      {" "}
                      <span>Planned Start Date</span> <br />
                      <b>{project?.data?.planned_start_date}</b>
                    </div>
                  </div>
                  <div className='info-card'>
                    <div className='icon'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4.92188 19.0312H7.26563C7.39453 19.0312 7.5 18.9258 7.5 18.7969L7.5 5.20312C7.5 5.07422 7.39453 4.96875 7.26562 4.96875H4.92188C4.79297 4.96875 4.6875 5.07422 4.6875 5.20312L4.6875 18.7969C4.6875 18.9258 4.79297 19.0312 4.92188 19.0312ZM10.5469 10.8281H12.8906C13.0195 10.8281 13.125 10.7227 13.125 10.5938V5.20312C13.125 5.07422 13.0195 4.96875 12.8906 4.96875H10.5469C10.418 4.96875 10.3125 5.07422 10.3125 5.20312V10.5938C10.3125 10.7227 10.418 10.8281 10.5469 10.8281ZM16.1719 12.9375H18.5156C18.6445 12.9375 18.75 12.832 18.75 12.7031V5.20312C18.75 5.07422 18.6445 4.96875 18.5156 4.96875H16.1719C16.043 4.96875 15.9375 5.07422 15.9375 5.20312V12.7031C15.9375 12.832 16.043 12.9375 16.1719 12.9375ZM22.5 0.28125L0.9375 0.28125C0.418945 0.28125 0 0.700195 0 1.21875L0 22.7812C0 23.2998 0.418945 23.7188 0.9375 23.7188H22.5C23.0186 23.7188 23.4375 23.2998 23.4375 22.7812V1.21875C23.4375 0.700195 23.0186 0.28125 22.5 0.28125ZM21.3281 21.6094H2.10938L2.10938 2.39062L21.3281 2.39062V21.6094Z'
                          fill='#8C8CFF'
                        />
                      </svg>
                    </div>{" "}
                    <div>
                      {" "}
                      <span>Project Type</span> <br />
                      <b className='bg'>{project.data?.project_type}</b>
                    </div>
                  </div>
                </div>
                <Divider />
                <div className='project-info-card-body'>
                  <b>Overview</b>
                  <p>{project.data?.project_desc}</p>
                  <b>Project Milestones</b>
                  <div className='project-milestones-container'>
                    {/* {console.log(project?.data?.project_milestones)} */}
                    {project?.data?.project_milestones?.length <= 0 ? (
                      <>No project milestones available</>
                    ) : (
                      <>
                        {project?.data?.project_milestones?.map((milestone) => (
                          <div
                            className='milestone d-flex gap-3'
                            key={milestone?.milestone_id}
                          >
                            <div className='icon'>
                              <img src={greenTick} alt='tick icon' />
                            </div>
                            <div className='text'>
                              {milestone?.milestone_name}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                  <b>Gallery</b>
                  <div className='gallery-container container !p-0 '>
                    <div className='flex flex-wrap gap-4'>
                      {project?.data?.project_images?.length <= 0 ? (
                        <>No Image for this project</>
                      ) : (
                        <>
                          {project?.data?.project_images?.map((image, i) => (
                            <div
                              className='col-4 col-md-3 h-36 w-36 cursor-pointer hover:opacity-60'
                              key={i}
                              onClick={() => openModal(image.image)}
                            >
                              <img
                                src={image.image}
                                className='h-full w-full object-cover bg-cover'
                                alt=''
                              />
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>

                  {/* Modal */}
                  {isModalOpen && (
                    <div className='fixed inset-0 z-50 overflow-auto bg-black bg-opacity-75 flex'>
                      <div className='relative p-4 mx-auto my-auto max-w-full max-h-full'>
                        <button
                          onClick={closeModal}
                          className='absolute bottom-2 right-2 rounded-lg m-4 text-white bg-native-blue w-14 text-3xl '
                        >
                          &times;
                        </button>
                        <div className='max-w-full max-h-full'>
                          <img
                            src={selectedImage}
                            className='max-w-full max-h-full'
                            alt=''
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProjectPage;
