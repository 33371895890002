import React, { useState, useContext } from "react";
import axios from 'axios';
import "./Modal.scss";
import imageOne from "../../assets/images/owner.png";
import imageTwo from "../../assets/images/contractor.jpg";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import { TostMessageContext } from "../../context/TostMessage";
import { IoMdArrowRoundBack } from "react-icons/io";

const Modal = ({ isOpen, onClose }) => {
    // Always call useState at the top level
    const [category, setCategory] = useState('');
    const [showModal, setShowModal] = useState(true);
    const [showOwner, setShowOwner] = useState(false);
    const [showContractor, setShowContractor] = useState(false);
    const [cookies] = useCookies();
    const { setTostMessage } = React.useContext(TostMessageContext);
    const { isLoggedIn, isLoading} = useContext(AuthContext);
    const { userProfile, forceLogOutFunction, getUserProfileFunction } = useContext(AuthContext);

    // State to store form data
    const [formData, setFormData] = useState({
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.account_id,
        account_type: 'client',
        currentSituation: '',
        discoveryMethod: ''
    });
    const [formData2, setFormData2] = useState({
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.account_id,
        account_type: 'contractor',
        contractorType: '',
        teamSize: '',
        receiveOpportunities: ''
      });
    // State to manage loading state
    const [loading, setLoading] = useState(false);

    if (!isOpen) return null;

    const handleOptionClick = (cat) => {
        setCategory(cat);
    };

    const toggleNextModal = () => {
        if (category == 'owner'){
            setShowOwner(true);
            setShowModal(false);
        }else{
            setShowContractor(true);
            setShowModal(false);
        }
        
    };
    

    // Function to handle form input changes
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
        ...formData,
        [name]: value
        });
    };

    const handleInputChange2 = (event) => {
        const { name, value } = event.target;
        setFormData2({
        ...formData2,
        [name]: value
        });
    };

    const handleBack = () => {
        setShowOwner(false);
        setShowModal(true);
        setShowContractor(false);
    }

    // Function to handle form submission
    const handleSubmit = async () => {
        setLoading(true); // Start loading
          try {
            const response = await axios.post('https://api.urbex.africa/backend/profile/set_onwer_survey.php', formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "access": cookies.urbexUserToken, // Ensure the header name and value are correctly set
                },
                
            });

            setLoading(false);
            if (response.data.statusCode === 401) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                forceLogOutFunction();
                return;
              }
              if (!response.data.status) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                return;
            }
            setTostMessage({
                messageType: "success",
                message: response.data.response,
            });
            getUserProfileFunction();
          } catch (error) {
            setLoading(false);
            return;
          }
    };
    
    const handleSubmit2 = async () => {
        setLoading(true); // Start loading
          try {
            const response = await axios.post('https://api.urbex.africa/backend/profile/set_contractor_survey.php', formData2, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "access": cookies.urbexUserToken, // Ensure the header name and value are correctly set
                },
                
            });
            setLoading(false);
            if (response.data.statusCode === 401) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                forceLogOutFunction();
                return;
              }
              if (!response.data.status) {
                setTostMessage({
                  messageType: "error",
                  message: response.data.response,
                });
                return;
            }
            setTostMessage({
                messageType: "success",
                message: response.data.response,
            });
            getUserProfileFunction();
          } catch (error) {
            setLoading(false);
            return;
          }
    };

    return (
        <div className="backdrop">
            {showModal && (
                <div className="modal_container justify-between">
                    <div className="text-left mb-0">
                        <h3 className="mb-0 text-[25px] font-bold">Hello {userProfile?.name}</h3>
                        <h3 className="mb-0 text-[25px] font-bold">Help us tailor your experience</h3>
                    </div>
                    <div className="options w-[90%] mx-auto my-5 items-center">
                        <div
                            className={`option p-0 ${category === 'owner' ? 'selected' : ''}`}
                            onClick={() => handleOptionClick('owner')}
                        >
                            <div className="relative w-full h-full">
                                <label htmlFor="projectOwner" className="w-full h-full grid overflow-hidden rounded-md cursor-pointer">
                                    <div className="h-[8rem] overflow-hidden">
                                        <img src={imageOne} alt="Project Owner" className="object-cover h-full w-full" />
                                    </div>
                                    <div className="flex flex-col items-start text-start gap-y-2 py-2 px-2">
                                        <h5 className="m-0 font-bold">Project Owner</h5>
                                        <p className="m-0">I want to monitor my own projects.</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <h3 className="text-sm font-bold">OR</h3>
                        <div
                            className={`option p-0 ${category === 'contractor' ? 'selected' : ''}`}
                            onClick={() => handleOptionClick('contractor')}
                        >
                            <div className="relative w-full h-full">
                                <label htmlFor="contractor" className="w-full h-full grid verflow-hidden rounded-md cursor-pointer">
                                    <div className="h-[8rem] overflow-hidden">
                                        <img src={imageTwo} alt="Contractor" className="object-cover h-full w-full" />
                                    </div>
                                    <div className="flex flex-col items-start text-start gap-y-2 py-2 px-2">
                                        <h5 className="m-0 font-bold">Contractor</h5>
                                        <p className="m-0">I am a contractor looking for work.</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                    {category == '' ? 
                    (<button className="continue w-4/5 mx-auto !rounded-full disabled" type="button" role="disabled">
                        Continue
                    </button>):
                    (<button onClick={toggleNextModal} className="bg-native-blue continue w-4/5 mx-auto !rounded-full" type="button">
                        Continue
                    </button>) }
                    
                </div>
            )}

            {showOwner && (
                <div className="modal_container">
                    <div className="text-left mb-3">
                        <button
                            className={`bg-transparent w-auto mb-3 !rounded-full border-3 border-black p-1`}
                            onClick={handleBack}
                            type="button"
                        >
                            <IoMdArrowRoundBack size={20} />
                        </button>
                        <h3 className="mb-0 text-[25px] font-bold">You're almost done {userProfile?.name}</h3>
                        <h3 className="mb-0 text-[25px] font-bold">Help us tailor your experience</h3>
                    </div>
                    <div className="flex flex-col gap-y-2">
                        <div className="flex flex-col items-start">
                            <label htmlFor="currentSituation">What best describes your current situation?</label>
                            <select
                            name="currentSituation"
                            id="currentSituation"
                            className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                            value={formData.currentSituation}
                            onChange={handleInputChange}
                            >
                            <option value="">Select an option</option>
                            <option value="I have an existing project">I have an existing project</option>
                            <option value="I am starting a new project">I am starting a new project</option>
                            <option value="I am exploring options">I am exploring options</option>
                            </select>
                        </div>
                        <div className="flex flex-col items-start">
                            <label htmlFor="discoveryMethod">How did you hear about Urbex?</label>
                            <select
                            name="discoveryMethod"
                            id="discoveryMethod"
                            className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                            value={formData.discoveryMethod}
                            onChange={handleInputChange}
                            >
                            <option value="">Select an option</option>
                            <option value="Through a friend">Through a friend</option>
                            <option value="Online advertisement">Online advertisement</option>
                            <option value="Sales reps">An Urbex sales rep.</option>
                            <option value="other">Other</option>
                            </select>
                        </div>
                        <div className="flex justify-content-between">
                            {(formData.discoveryMethod == '' || formData.currentSituation == '') ? 
                            (<button className="continue w-50 mt-3 mx-auto !rounded-full disabled" type="button" role="disabled">
                                Complete
                            </button>):
                            (<button
                                className={`bg-native-blue continue w-50 mx-auto mt-3 !rounded-full ${loading ? 'loading' : ''}`}
                                onClick={handleSubmit}
                                type="button"
                                disabled={loading}
                            >
                                {loading ? 'Please Wait...' : 'Complete'}
                            </button>) }
                            
                        </div>
                        
                        </div>
                </div>
            )}

        {showContractor && (
            <div className="modal_container">
            <div className="text-left mb-3">
                <button
                    className={`bg-transparent w-auto mb-3 !rounded-full border-3 border-black p-1`}
                    onClick={handleBack}
                    type="button"
                >
                    <IoMdArrowRoundBack size={20} />
                </button>
                <h3 className="mb-0 text-[25px] font-bold">You're almost done {userProfile?.name}</h3>
                <h3 className="mb-0 text-[25px] font-bold">Help us tailor your experience</h3>
            </div>
            <div className="flex flex-col gap-y-2">
                <div className="flex flex-col items-start">
                <label htmlFor="contractorType">What best describes your current situation?</label>
                <select
                    name="contractorType"
                    id="contractorType"
                    className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                    value={formData2.contractorType}
                    onChange={handleInputChange2}
                >
                    <option value="">Select an option</option>
                    <option value="solo contractor">I am a solo contractor</option>
                    <option value="construction company">I am a construction company</option>
                </select>
                </div>
                <div className="flex flex-col items-start">
                <label htmlFor="teamSize">How big is your team?</label>
                <select
                    name="teamSize"
                    id="teamSize"
                    className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                    value={formData2.teamSize}
                    onChange={handleInputChange2}
                >
                    <option value="">Select an option</option>
                    <option value="1-5">1-5</option>
                    <option value="6-10">6-10</option>
                    <option value=">10">More than 10</option>
                </select>
                </div>
                <div className="flex flex-col items-start">
                <label htmlFor="receiveOpportunities">Would you like to receive contract opportunities from Urbex?</label>
                <select
                    name="receiveOpportunities"
                    id="receiveOpportunities"
                    className="bg-slate-100 border-none rounded-md text-[.9rem] w-full"
                    value={formData2.receiveOpportunities}
                    onChange={handleInputChange2}
                >
                    <option value="">Select an option</option>
                    <option value="receive job notifications">Yes</option>
                    <option value="do not receive job notifications">No</option>
                </select>
                </div>
                <div className="flex justify-content-between">
                    {(formData2.contractorType == '' || formData2.teamSize == '' || formData2.receiveOpportunities == '') ? 
                    (<button className="continue w-50 mt-3 mx-auto !rounded-full disabled" type="button" disabled>
                        Complete
                    </button>) :
                    (<button
                        className={`bg-native-blue continue w-50 mx-auto mt-3 !rounded-full ${loading ? 'loading' : ''}`}
                        onClick={handleSubmit2}
                        type="button"
                        disabled={loading}
                    >
                        {loading ? 'Please Wait...' : 'Complete'}
                    </button>) }
                </div>

            </div>
            </div>
        )}
        </div>
    );
};

export default Modal;
