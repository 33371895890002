import React, {
  useContext, useEffect,
  // ,
  // useEffect,
  //   useState
} from "react";
import { BsX } from "react-icons/bs";
import "./TostMessageComponent.scss";
import { TostMessageContext } from "./../../context/TostMessage";

import errorImg from "../../assets/icons/Vector.svg";
import successImg from "../../assets/icons/Vector-2.svg";
import { FaCheck } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { CgDanger } from "react-icons/cg";

function TostMessageComponent() {
  const { tostMessage, setTostMessage } = useContext(TostMessageContext);

  useEffect(() => {
    if (tostMessage.messageType != "") {
      setTimeout(() => {
        setTostMessage({ messageType: "", message: "" })
      }, 5000)
    }
  }, [tostMessage])

  // let icon;

  // if (tostMessage.messageType === "error") {
  //   icon = errorImg;
  // } else if (tostMessage.messageType === "success") {
  //   icon = successImg;
  // } else if (tostMessage.messageType === "warning") {
  //   icon = warningImg;
  // }

  return (
    <>
    {/* <div className={`TostMessageComponent ${tostMessage.messageType}`}
      style={tostMessage.messageType !== "" ? null : { display: "none" }}
    >
      <div className="icon-container">
        {tostMessage.messageType === "error" ? (
          <img src={errorImg} alt="error" />
        ) : (
          <img src={successImg} alt="success" />
        )}
      </div>
      <div className="text-container">
        <h3>{tostMessage.messageType}</h3>
        <p className="w-100">{tostMessage.message}</p>
      </div>
      <div className="close">
        <BsX onClick={() => setTostMessage({ messageType: "", message: "" })} />
      </div>
    </div> */}
    {tostMessage.messageType == "success" && (
      <div onClick={() => setTostMessage({ messageType: "", message: "" })} className="opacity-[0.99] overflow-hidden shadow-2xl border-b-solid border-b-[5px] border-[#01E17B] flex flex-row justify-between items-center position-fixed right-3 top-7 sm:right-7 z-[100000] w-auto py-3 rounded-[10px] bg-[#242C32]">
        <div className="outline outline-[#ffffff]/[.20] outline-[8px]  shadow-3xl-green h-7 w-7 flex items-center justify-center bg-[#01E17B] mx-[30px] rounded-[50%]">
          <FaCheck color="#242C32" size={15} />
        </div>
        <div className="px-3 flex flex-column max-w-[300px]">
            <b className="text-sm text-white">{tostMessage.message}</b>
        </div>
      </div>
    )}
    {tostMessage.messageType == "warning" && (
      <div onClick={() => setTostMessage({ messageType: "", message: "" })} className="opacity-[0.99] overflow-hidden shadow-2xl border-b-solid border-b-[5px] border-[#FFD21F] flex flex-row justify-between items-center position-fixed right-3 top-7 sm:right-7 z-[100000] w-auto py-3 rounded-[10px] bg-[#242C32]">
        <div className="outline outline-[#ffffff]/[.20] outline-[8px]  shadow-3xl-yellow h-7 w-7 flex items-center justify-center bg-[#FFD21F] mx-[30px] rounded-[50%]">
          <IoWarning color="#242C32" size={15} />
        </div>
        <div className="px-3 flex flex-column max-w-[300px]">
            <b className="text-sm text-white">{tostMessage.message}</b>
        </div>
      </div>
    )}
    {tostMessage.messageType == "error" && (
      <div onClick={() => setTostMessage({ messageType: "", message: "" })} className="opacity-[0.99] overflow-hidden shadow-2xl border-b-solid border-b-[5px] border-[#ff0000] flex flex-row justify-between items-center position-fixed right-3 top-7 sm:right-7 z-[100000] w-auto py-3 rounded-[10px] bg-[#242C32]">
        <div className="outline outline-[#ffffff]/[.20] outline-[8px]  shadow-3xl-yellow h-7 w-7 flex items-center justify-center bg-[#ff0000] mx-[30px] rounded-[50%]">
          <CgDanger color="#242C32" size={15} />
        </div>
        <div className="px-3 flex flex-column max-w-[300px]">
            <b className="text-sm text-white">{tostMessage.message}</b>
        </div>
      </div>
    )}
    </>
  );
}

export default TostMessageComponent;
