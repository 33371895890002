import React from 'react';
import styled from 'styled-components';

// Styled components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ecf0f1;
  color: #2c3e50;
`;

const StatusIndicator = styled.div`
  width: 20px;
  height: 20px;
  background-color: #2ecc71;
  border-radius: 50%;
  margin-bottom: 8px;
`;

const Title = styled.h2`
  font-size: 18px;
  margin-top: 8px;
`;

const ConfirmationMessage = styled.h1`
  font-size: 24px;
  margin: 16px 0;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 8px;
  border: none;
  background-color: #27ae60;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #229954;
  }
`;

// React component
const WithdrawalSuccess = () => {

  const transactionId = '#123RGR231567Y';

  return (
    <Container>
      <StatusIndicator />
      <Title>Withdrawal Successful</Title>
      <ConfirmationMessage>Withdrawal {transactionId} Confirmed</ConfirmationMessage>
      <Button onClick={() => window.location.href = '/'}>Back To Home</Button>
      {/* Replace '#' with actual function or link to generate a receipt */}
      <Button onClick={() => alert('Receipt generation not implemented')}>Generate Receipt</Button>
    </Container>
  );
}

export default WithdrawalSuccess;
