import React, { useState, useContext } from "react";
import axios from 'axios';
import "./Modal.scss";
import imageOne from "../../assets/images/owner.png";
import imageTwo from "../../assets/images/contractor.jpg";
import { AuthContext } from "../../context/AuthContext";
import { useCookies } from "react-cookie";
import { TostMessageContext } from "../../context/TostMessage";
import { IoMdArrowRoundBack,IoMdClose } from "react-icons/io";
import {
    useNavigate,
} from "react-router-dom";

const WelcomeModal = ({ isOpen, onClose, setIsWelcomeModalOpen }) => {
    // Always call useState at the top level
    const [showModal, setShowModal] = useState(true);
    const [cookies] = useCookies();
    const navigate = useNavigate();
    const { setTostMessage } = React.useContext(TostMessageContext);
    const { isLoggedIn, isLoading} = useContext(AuthContext);
    const { userProfile, forceLogOutFunction, getUserProfileFunction } = useContext(AuthContext);

    // State to store form data
    
    if (!isOpen) return null;



    return (
        <div className="backdrop">
            {showModal && (
                <div className="modal_container justify-start">
                    <div className="w-full flex flex-column items-end">
                        <button onClick={() => setIsWelcomeModalOpen(false)} className="h-10 w-10 flex items-center justify-center rounded-full bg-[#ff0000]">
                            <IoMdClose size={20} color="#ffffff" />
                        </button>
                    </div>
                    <div className="text-left mb-0">
                        <h3 className="mb-0 text-[25px] font-bold">Wondering what next?</h3>
                        <h3 className="mb-0 text-[17px] font-semibold">Create your first project</h3>
                    </div>
                    <div className="w-[100%] gap-y-2 flex flex-column mx-auto my-3 items-center">
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[20px]">1</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">Tell us about your project</a>
                        </div>
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[17px]">2</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">A supervisor will reach out to you</a>
                        </div>
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[17px]">3</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">A project manager that bests suits your project will be assigned</a>
                        </div>
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[17px]">4</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">Activate your monthly subscription</a>
                        </div>
                        <div className="w-full flex flex-row items-center gap-x-4">
                            <div className="h-10 w-10 bg-native-blue flex justify-center items-center font-bold rounded-full">
                                <b className="text-white font-bold text-[17px]">5</b>
                            </div>
                            <a className="w-[90%] text-start font-semibold text-[15px]">Voila! Its that easy</a>
                        </div>
                    </div>
                    <button onClick={() => {navigate("/dashboard/create-project");setIsWelcomeModalOpen(false); }} className="bg-native-blue continue w-4/5 mx-auto !rounded-full" type="button">
                        Create First Project
                    </button>
                </div>
            )}
        </div>
    );
};

export default WelcomeModal;
