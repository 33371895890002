import React from "react";

function IntroductionStep({ handelNext }) {
  return (
    <div className="IntroductionStep">
      <ul>
          <li>CAC Reg.</li>
        <li>Valid Utility Bill</li>
      </ul>

      <div className="" style={{ fontSize: 14 }}>
        <b>Note</b> <br />
        Uploaded documents must be in png, jpg, jpeg or pdf formats. Passport
        photographs must be against plain background. Acceptable IDs are
        Driver’s license, International Passport, National identity card or
        Voter’s card. Please, ensure all information submitted hereafter are
        valid and adhere to the guidelines stated above to reduce risk of your
        verification request being rejected.
      </div>
      <button
        className="next-button mt-3 mb-3 mx-auto"
        onClick={() => handelNext()}
      >
        Next
      </button>
    </div>
  );
}

export default IntroductionStep;
