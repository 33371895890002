import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { BsArrowRightShort, BsChevronDown, BsThreeDots } from "react-icons/bs";
import { MdInsertChart } from "react-icons/md";
import "./HomePage.scss";
import AccountSetUpCard from "./../../components/AccountSetUpCard/AccountSetUpCard";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { fetch_notifications_end_point } from "../../config/variables";
import { useCookies } from "react-cookie";
import axios from "axios";
import OverviewComponent from "./../../components/OverviewComponent/OverviewComponent";
import { ProjectCardComponent } from "../SearchProjectPage/SearchProjectPage";
import { ProjectsContext } from "../../context/ProjectsContext";
import BarChatComponent from "../../components/BarChatComponent/BarChatComponent";
import ReactLoading from "react-loading";
import { MdFileDownload } from "react-icons/md";

function HomePage() {
  const { userProfile } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   // console.log("[userProfile:]", userProfile);
  // }, []);
  const { getUserProfileFunction } = useContext(AuthContext);
  useEffect(() => {
    getUserProfileFunction();
    setLoading(false);
  }, []);

  // // console.log('persist', userProfile);
  // useEffect(() => {
  //   if (userProfile) {
  //     setTimeout(() => {
  //     }, 500);
  //   }
  // }, [userProfile]);

  return (
    <div className='HomePage' id="tour_urbex_dashboard">
      <div className='page-name-container'>
        <div>
          <div className='page-name'>Dashboard</div>
          <p>
            Hi 👋, welcome
            <b> {userProfile?.name} </b>
            to your Urbex PRO dashboard{" "}
          </p>
        </div>
        <div>
          {/* <Menu isLazy> */}
          {/* <MenuButton as={Button} rightIcon={<BsChevronDown />}> */}
          {/* My Project */}
          {/* </MenuButton> */}
          {/* <MenuList> */}
          {/* MenuItems are not rendered unless Menu is open */}
          {/* <MenuItem>New Window</MenuItem> */}
          {/* <MenuItem>Open Closed Tab</MenuItem> */}
          {/* <MenuItem>Open File</MenuItem> */}
          {/* </MenuList> */}
          {/* </Menu> */}
          {/* <Icon as={MdInsertChart} w={6} h={6} className="ms-2" /> */}
        </div>
      </div>
      <div className=''>
        <div className='xl:grid xl:grid-cols-7 gap-4 px-4 md:px-10 lg:px-20'>
          <div className='md:col-span-5'>
            <div>
              {userProfile?.docs_stage === "VERIFIED" ? null : (
                <AccountSetUpCard profileName={userProfile?.name} />
              )}
            </div>
            {/* Overview section */}
            {loading ? (
              <div className='border mb-4 w-full rounded-xl p-4'>
                <div className='grid my-3 grid-cols-3 gap-4'>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                </div>{" "}
                <div className='grid my-3 grid-cols-3 gap-4'>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                </div>{" "}
                <div className='grid my-3 grid-cols-3 gap-4'>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                </div>
                <div className='grid my-3 grid-cols-3 gap-4'>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                  <div className='bg-gray-200 w-full rounded-lg animate-pulse h-20'></div>
                </div>
              </div>
            ) : (
              <OverviewComponent userProfile={userProfile} />
            )}
          </div>
          <OngoingMilestones />
        </div>
        {/* Recommended Projects */}
        {/*         <div className="recommended-projects-container">
          <div className="top-div">
            <h1>Recommended Projects</h1>
            <div>
              <Link to={"/dashboard/search-project"}>
                View More <BsArrowRightShort />
              </Link>
            </div>
          </div>

          <div className="project-list-container">
            {listProjects?.loading ? (
              <div className="mt-5 fs-3 fw-bolder text-muted">Loading...</div>
            ) : (
              <>
                {listProjects?.data && listProjects?.data?.length <= 0 ? (
                  <div className="mt-5 fs-3 fw-bolder text-muted">
                    No Project
                  </div>
                ) : (
                  <>
                    {listProjects?.data ? (
                      <>
                        {listProjects?.data[0] ? (
                          <ProjectCardComponent
                            project={listProjects?.data[0]}
                          />
                        ) : null}
                        {listProjects?.data[1] ? (
                          <ProjectCardComponent
                            project={listProjects?.data[1]}
                          />
                        ) : null}
                        {listProjects?.data[2] ? (
                          <ProjectCardComponent
                            project={listProjects?.data[2]}
                          />
                        ) : null}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default HomePage;

export function OngoingMilestones() {
  const [loading, setLoading] = useState(true);

  // const [cookies] = useCookies();
  const { userProfile, getUserProfileFunction } = useContext(AuthContext);
  const [ongoingMilestones, setOngoingMilestones] = useState(null);
  const [cookies] = useCookies();

  useEffect(() => {
    getUserProfileFunction();
  }, []);

  useEffect(() => {
    if (userProfile) {
      setOngoingMilestones(userProfile?.ongoing_milestones);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [userProfile]);

  return (
    <div className='b grid xl:col-span-2 rounded-xl pt-2 border hover:bg-white p-2' id="tour_site_activities">
      <div className='overflow-x-hidden pt-2'>
        <div className='mb-4 flex flex-row justify-between items-center'>
          <div className='font-semibold'>Ongoing Activities</div>
          {/* <button
            onClick={() => {
              window.open(
                `https://api.urbex.africa/backend/reports/ongoing_activities.php?account_id=${cookies.urbexUserToken}`,
                '_blank'
              );
            }}
            className="border py-1 px-1 flex flex-row cursor-pointer rounded-full bg-transparent text-native-blue text-sm"
          >
            <MdFileDownload className='text-native-blue' size={20} />
          </button> */}
          <Link
            to={"/dashboard/ongoing-projects"}
            className='border py-1 px-2 cursor-pointer rounded-full bg-native-blue text-white text-sm'
          >
            View
          </Link>
        </div>
        {loading ? (
          <>
            {[1, 2, 3, 4].map((i, idx) => (
              <div
                key={idx}
                className='animate-pulse py-10 transition-all ease-in-out cursor-pointer bg-gray-200 my-2 rounded-xl'
              ></div>
            ))}
          </>
        ) : (
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-1 gap-4'>
            {ongoingMilestones?.length === 0 ? (
              <div className='text-center mt-20'>No activities to show...</div>
            ) : (
              <>
                {ongoingMilestones?.slice(0, 3).map((item, i) => {
                  const milestone_metric_target_covered = Number(
                    item.milestone_metric_target_covered
                  );
                  const milestone_metric_target = Number(
                    item.milestone_metric_target
                  );
                  const percentageNum =
                    (milestone_metric_target_covered /
                      milestone_metric_target) *
                    100;
                  const percentage = isNaN(percentageNum)
                    ? 1
                    : Math.ceil(percentageNum);

                  return (
                    <Link
                      key={i}
                      to={`/dashboard/ongoing-projects/${item.project_id}`}
                    >
                      <div className='border transition-all ease-in-out hover:bg-zinc-100 cursor-pointer bg-gray-100 my-2 p-2 rounded-xl'>
                        <div className='font-semibold text-xs text-slate-500'>
                          {item.project_name}
                        </div>
                        <div className='text-sm font-mono font-semibold my-2'>
                          {item.milestone} ({item.milestone_metric_target}{" "}
                          {item.milestone_metric_system})
                        </div>
                        <div className='text-xs'>
                          Started {item.duration_elapsed}
                        </div>{" "}
                        <div className='text-xs'>
                          Duration Set: {item.duration}
                        </div>
                        <div className='flex justify-between mb-1 mt-2'>
                          <span className='text-xs font-medium text-blue-700 dark:text-white'>
                            {item.milestone_metric_target_covered} of{" "}
                            {item.milestone_metric_target}{" "}
                            {item.milestone_metric_system}
                          </span>
                          <span className='text-sm font-medium text-blue-700 dark:text-white'>
                            {percentage}%
                          </span>
                        </div>
                        <div className='w-full bg-gray-200 rounded-full h-2 dark:bg-gray-700'>
                          <div
                            className='bg-native-blue h-2 rounded-full'
                            style={{ width: `${percentage}%` }}
                          ></div>
                        </div>
                        <button className='bg-native-blue w-full p-2 rounded-lg text-white mt-2 text-sm active:scale-105 transition-all ease-in-out'>
                          View
                        </button>
                      </div>
                    </Link>
                  );
                })}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
