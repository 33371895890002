import "./App.scss";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import LandingPage from './pages/LandingPage/LandingPage';
import LoginPage from "./pages/AuthPage/LoginPage";
import RegisterPage from "./pages/AuthPage/RegisterPage";
import OtpVerificationPage from "./pages/AuthPage/OtpVerificationPage";
// import { ToastContainer } from 'react-toastify';
import PasswordResetPage from "./pages/AuthPage/PasswordResetPage";
import Dashboard from "./pages/Dashboard/Dashboard";
import HomePage from "./pages/HomePage/HomePage";
import CreateProjectPage from "./pages/CreateProjectPage/CreateProjectPage";
import SearchProjectPage from "./pages/SearchProjectPage/SearchProjectPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
// import { useEffect } from 'react';
import TostMessageComponent from "./components/TostMessageComponent/TostMessageComponent";
import OngoingProjectsPage from "./pages/OngoingProjectsPage/OngoingProjectsPage";
import PastProjectsPage from "./pages/PastProjectsPage/PastProjectsPage";
import FinancePage from "./pages/FinancePage/FinancePage";
import ProjectPage from "./pages/ProjectPage/ProjectPage";
import { VerificationProvider } from "./context/VerificationContext";
import ViewOngoingProjectsPage from "./pages/OngoingProjectsPage/ViewOngoingProjectsPage";
import { ProjectsProvider } from "./context/ProjectsContext";
import ApplicationPage from "./pages/ApplicationPage/ApplicationPage";
import Subscriptions from "./pages/Subscriptions/Subscriptions";
import ChatPage from "./pages/ChatPage/ChatPage";
import Error404Page from "./pages/ErrorPage/Error404Page";
import ErrorOccurred from "./pages/ToastPage/ErrorOccurred";
import SearchQueryEmpty from "./pages/ToastPage/SearchQueryEmpty";
import WithdrawalSuccess from "./pages/ToastPage/WithdrawalSuccess";
import CallPage, { VideoCallCardHover } from "./pages/CallPage/CallPage.jsx";
// import ProjectBidsPage from "./pages/ProjectBidsPage/ProjectBidsPage";
import FAQPage from "./pages/FAQPage/FAQPage";
import ActivityTracker from "./pages/ActivityTracker/ActivityTracker.jsx";
import {
  VideoCallContext,
  VideoCallProvider,
} from "./context/videoCallContext";
import { useContext, useEffect } from "react";
import { PushNotificationContext } from "./context/PushNotificationContext";
import ProjectsGallery from "./pages/Gallery/projects-gallery";
import { AuthContext } from "./context/AuthContext.jsx";
import { TourProvider } from "@reactour/tour";
import { generateToken, messaging } from "./notification/firebase.js";
import { onMessage } from "firebase/messaging";
import toast, { Toaster } from "react-hot-toast";
import { useCookies } from "react-cookie";
import addNotification, { Notifications } from "react-push-notification";

function App() {
  const [cookies] = useCookies();

  const { callButtonClicked, incomingCall, setIncomingCall } =
    useContext(VideoCallContext);
  const {
    isPushNotificationSupported,
    sendNotification,
    initializePushNotifications,
    registerServiceWorker,
  } = useContext(PushNotificationContext);
  const { getUserProfileFunction } = useContext(AuthContext);
  const { userProfile } = useContext(AuthContext);

  // useEffect(() => {
  //   if (!userProfile?.account_id) getUserProfileFunction();
  // }, []);

  useEffect(() => {
    generateToken(cookies?.urbexUserToken, cookies?.userAccountIds?.account_id);
    onMessage(messaging, (payload) => {
      addNotification({
        title: payload.notification.title,
        message: payload.notification.body,
        theme: "darkblue",
        native: true,
        icon: payload.notification.image,
      });
    });
  }, [userProfile]);

  useEffect(() => {
    if (!userProfile?.account_id) getUserProfileFunction();
    const pushNotificationSuported = isPushNotificationSupported();
    if (pushNotificationSuported) {
      registerServiceWorker();
      initializePushNotifications().then(function (consent) {
        if (consent === "granted") {
          // sendNotification();
        }
      });
    }
  }, []);
  return (
    <div>
      <Notifications />
      {/* remove the call overlay */}
      {/* {callButtonClicked ? <CallPage /> : null} */}

      {/*Uncomment when call hover card is needed */}
      {/* {incomingCall ? (
        <VideoCallCardHover setIncomingCall={setIncomingCall} />
      ) : null} */}
      {/* <CompExample /> */}
      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      /> */}
      <TourProvider steps={steps}>
        <TostMessageComponent />
        <Routes>
          {/* landing page route */}
          {/* <Route  path="/" element={<LandingPage />} /> */}
          {/* redirect to login page */}
          <Route exact path='/' element={<Navigate replace to='/login' />} />
          <Route exact path='/login' element={<LoginPage />} />
          <Route exact path='/register' element={<RegisterPage />} />
          <Route
            exact
            path='/verify/:email'
            element={<OtpVerificationPage />}
          />
          <Route exact path='/password-reset' element={<PasswordResetPage />} />

          <Route
            exact
            path='/dashboard'
            element={<Navigate replace to='/dashboard/home' />}
          />
          <Route
            path='/dashboard'
            element={
              <VerificationProvider>
                <ProjectsProvider>
                  <Dashboard />
                </ProjectsProvider>
              </VerificationProvider>
            }
          >
            <Route path='home' element={<HomePage />} />
            <Route
              path='search-project'
              element={
                <ProjectsProvider>
                  <SearchProjectPage />
                </ProjectsProvider>
              }
            />
            <Route
              path='search-project/:id'
              element={
                <ProjectsProvider>
                  <ProjectPage />
                </ProjectsProvider>
              }
            />
            <Route
              path='ongoing-projects'
              exact
              element={<OngoingProjectsPage />}
            />
            <Route
              path='projects-gallery'
              exact
              element={<ProjectsGallery />}
            />
            <Route
              path='activity-tracker'
              exact
              element={<ActivityTracker />}
            />
            <Route
              path='ongoing-projects/:id'
              element={<ViewOngoingProjectsPage />}
            />
            <Route
              path='ongoing-projects/:id/subscriptions/:id'
              exact
              element={<Subscriptions />}
            />
            <Route path='past-projects' element={<PastProjectsPage />} />
            {/* <Route path="project-bids" element={<ProjectBidsPage />} /> */}
            <Route path='create-project' element={<CreateProjectPage />} />
            <Route path='applications' element={<ApplicationPage />} />
            <Route
              path='past-projects/:id'
              element={<ViewOngoingProjectsPage />}
            />
            <Route path='ongoing-projects/:id/chat' element={<ChatPage />} />
            <Route path='ongoing-projects/:id/call' element={<CallPage />} />
            <Route path='finance' element={<FinancePage />} />
            <Route path='profile' element={<ProfilePage />} />
            <Route path='faq' element={<FAQPage />} />
          </Route>

          <Route path='*' element={<Navigate replace to='/404' />} />
          <Route path='/404' element={<Error404Page />} />
          <Route path='/error' element={<ErrorOccurred />} />
          <Route path='/withdrawal-success' element={<WithdrawalSuccess />} />
          <Route path='/search-query-empty' element={<SearchQueryEmpty />} />
        </Routes>
      </TourProvider>
    </div>
  );
}

export default App;
const steps = [
  {
    selector: "#tour_urbex_dashboard",
    content:
      "Welcome to your Urbex PRO dashboard! Urbex PRO gives you total control & realtime updates on all your construction projects from anywhere in the world",
  },
  {
    selector: ".over-view-container",
    content:
      "This shows a brief overview of all your ongoing projects at a glance. Click on the project name at the top-right to toggle between projects.",
  },
  {
    selector: "#tour_site_activities",
    content:
      "This shows information on the activities currently ongoing across all your projects.",
  },
  {
    selector: "#tour_side_nav",
    content:
      "Use this to navigate between different sections of your dashboard.",
  },
  // {
  //   selector: '#tour_create_project',
  //   content: 'Create new projects from here. A member of our team will reach out to you to understand the details of your project and assign a project manager accordingly.'
  // }
];
