import React, { useContext, useEffect, useState } from 'react';
import bgImage from '../../assets/images/auth-bg.svg';

import { AuthContext } from '../../context/AuthContext';
import './AuthPage.scss';
import { PasswordReset } from '../../components/FormsComponent/PasswordReset';
import { ChangePassword } from '../../components/FormsComponent/ChangePassword';

function PasswordResetPage() {
  const { resetOtpSent, setResetOtpSent, SendOtpForPasswordRestFunction } =
    useContext(AuthContext);
  const [keepEmail, setKeepEmail] = useState('');
  useEffect(() => {
    function handleUrlChange() {
      setResetOtpSent(false);
      setKeepEmail('');
    }

    handleUrlChange();
  }, []);
  return (
    <div className="relative max-h-screen h-full">
      <img
        src={bgImage}
        alt="image"
        className="absolute top-0 right-0 left-0 bottom-0 h-screen object-cover w-full opacity-50"
      />
      <div className="absolute z-10 flex p-2 top-0 right-0 left-0 bottom-0 h-screen object-cover w-full">
        <div className="bg-[#ffffff] shadow-2xl rounded-xl p-4 max-w-[470px] flex flex-col m-auto">
          {resetOtpSent ? (
            <ChangePassword email={keepEmail} />
          ) : (
            <PasswordReset setKeepEmail={setKeepEmail} />
          )}
        </div>
      </div>
    </div>
  );
}

export default PasswordResetPage;
