import React from "react";
import { Link } from "react-router-dom";
// import "./OngoingProjectsPage.scss";
import { ProjectsContext } from "./../../context/ProjectsContext";
import {
  OngoingFilterScrollComponent,
  OngoingProjectCard,
} from "../OngoingProjectsPage/OngoingProjectsPage";

function PastProjectsPage() {
  const [projects, setProjects] = React.useState({
    loading: true,
    data: [],
  });

  const { userProjects, getUserProjects } = React.useContext(ProjectsContext);

  React.useEffect(() => {
    getUserProjects();
  }, []);
  React.useEffect(() => {
    // console.log("userProjects", userProjects?.data?.past_projects);

    if (userProjects.loading) {
      return;
    }
    setProjects({
      loading: false,
      data: userProjects?.data?.past_projects,
    });
  }, [userProjects]);
  return (
    <div className="OngoingProjectsPage ">
      {/* <OngoingFilterScrollComponent /> */}
      {projects?.loading ? (
        <h1 className="mt-5 text-center">Loading...</h1>
      ) : (
        <>
          {projects?.data?.length !== 0 ? (
            <>
              <h1 className="mt-5">Past Project</h1>
              {projects?.data?.map((project, i) => {
                return (
                  <div className="mx-auto" key={i}>
                    <OngoingProjectCard project={project} />
                  </div>
                );
              })}
              {/* <h1>Completed</h1>
              {projects.data?.map((project) => {
                return <>hi</>;
              })} */}
            </>
          ) : (
            <div className="no-project">
              <h1>You do not have any projects</h1>
              <Link to="/dashboard/search-project">Find Projects</Link>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default PastProjectsPage;
