import React from "react";
import { FilterScrollComponent } from "../OngoingProjectsPage/OngoingProjectsPage";
import "./FinancePage.scss";
import { Icon, Input } from "@chakra-ui/react";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";
import { useCookies } from "react-cookie";
import { view_user_financials_end_point } from "../../config/variables";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import fin_withdrawal_icon from "../../assets/icons/fin_withdrawal_icon.svg";
import fin_credited_icon from "../../assets/icons/fin_credited_icon.svg";
import filter_alt_black from "../../assets/icons/filter_alt_black.svg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

ChartJS.register(ArcElement, Tooltip, Legend);

function FinancePage() {
  const navigate = useNavigate();
  const [finances, setFinances] = React.useState({
    loading: true,
    data: [],
  });
  const [financesFilter, setFinancesFilter] = React.useState({
    filter: false,
    data: null,
  });
  const { userProfile } = React.useContext(AuthContext);
  const [cookies] = useCookies();

  async function getFinances() {
    const data = {
      user: {
        account_id: userProfile?.account_id,
        sub_account_id: userProfile?.sub_account_id,
      },
      data: {
        records: 10,
        offset: 0,
      },
    };
    try {
      const response = await axios.post(view_user_financials_end_point, data, {
        headers: {
          access: cookies.urbexUserToken,
        },
      });
      // console.log(response.data);
      // return response.data;
      setFinances({
        loading: false,
        data: response.data.fiancials,
      });
    } catch (error) {
      if (error.data.response == "Token expired, please log in.") {
        navigate(`/login`);
      }
    }
  }
  React.useEffect(() => {
    getFinances();
  }, []);
  function filterFinances(i) {
    // console.log("filterfinances", i);
    if (i === 0) {
      // console.log(finances);
      return setFinancesFilter({
        // console.log(finances);
        filter: false,
        data: null,
      });
    }
    let filterArray = [];
    if (i === 1) {
      finances.data?.transactions?.map((finance) => {
        if (finance.type === "Credit") {
          filterArray.push(finance);
        }
      });
    }
    if (i === 2) {
      // console.log(finances);
      finances.data?.transactions?.map((finance) => {
        if (finance.type === "Debit") {
          filterArray.push(finance);
        }
      });
    }
    setFinancesFilter({
      filter: true,
      data: filterArray,
    });
    // console.log("FinancesFilter", financesFilter);
  }
  const { register, handleSubmit } = useForm({
    defaultValues: {
      from: "",
      to: "",
      project: "",
      type: "",
    },
  });
  function formatDate(inputDate) {
    const [year, month, day] = inputDate.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
  const onSubmit = (data) => {
    const from = formatDate(data.from);
    const to = formatDate(data.to);

    window.open(
      `https://api.urbex.africa/backend/financials/export_financials.php?account_id=${cookies.urbexUserToken}&date_to=${to}&date_from=${from}&type=${data.type}`,
      "_blank"
    );
  };

  return (
    <div className='FinancePage px-3 lg:px-10 xl:px-20'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='grid grid-cols-2 gap-3'>
              {/* <div className="col-md-6"> */}
              <div className='p-4 bg-white rounded-xl hover:shadow-sm'>
                <div className='d-flex gap-3 align-items-center'>
                  {/*                   <div className="icon-container"></div> */}
                  <p className='text-[#00b47a]'>Funding Total</p>
                </div>
                <h3>₦{finances.data?.credits}</h3>
              </div>
              {/* </div> <div className="col-md-6"> */}
              <div className='p-4 bg-white rounded-xl hover:shadow-sm'>
                <div className='d-flex gap-3 align-items-center'>
                  {/*                   <div className="icon-container"></div> */}
                  <p className='text-[#ff0000]'>Withdrawal Total</p>
                </div>
                <h3>₦{finances.data?.debits}</h3>
                {/* </div> */}
              </div>
            </div>
            {/*             <FilterScrollComponent
              filtersArray={["All", "Fundings", "Withdrawals"]}
              handleFilerFunction={(i) => filterFinances(i)}
            /> */}

            <div className='transaction-section mt-5'>
              <div className='top mb-4'>
                <b>Recent Transactions</b>

                <div className='icon-container'>
                  {/* <Icon /> */}
                  <img src={filter_alt_black} alt='' />
                </div>
              </div>

              {finances.loading ? (
                <div>
                  {[1, 2, 3, 4, 5, 6].map((i, index) => (
                    <div
                      key={index}
                      className='h-14 w-full bg-gray-200 animate-pulse rounded-lg py-3 my-7'
                    ></div>
                  ))}
                </div>
              ) : (
                <div className='flex flex-col-reverse lg:grid grid-cols-5 gap-4'>
                  <div className='lg:col-span-3'>
                    {finances.data?.transactions?.map((finance, i) => {
                      return <TransactionCard finance={finance} key={i} />;
                    })}
                    <>
                      {financesFilter.filter === false ? (
                        <>
                          {finances.data?.transactions?.map((finance, i) => {
                            return (
                              <TransactionCard finance={finance} key={i} />
                            );
                          })}
                        </>
                      ) : null}
                      {financesFilter.filter === true ? (
                        <>
                          {financesFilter.data === null ||
                          financesFilter.data.length <= 0 ? (
                            <>No data available for filter</>
                          ) : (
                            <>
                              {financesFilter.data?.map((finance, i) => {
                                return (
                                  <TransactionCard finance={finance} key={i} />
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : null}
                    </>
                  </div>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className='lg:col-span-2 my-4 border p-4 bg-white rounded-xl'
                  >
                    <div className='p-3 border bg-gray-100 font-semibold text-lg rounded-lg'>
                      Download account statement & transaction records
                    </div>
                    <div className='gap-2 mt-4 flex flex-col items-center justify-between min-w-full'>
                      <div className='w-full'>
                        <sub>Start date</sub>
                        <input
                          type='date'
                          {...register("from")}
                          className='w-full mt-2 p-2 h-10 rounded-lg border-gray-300'
                        />
                      </div>
                      <div className='w-full'>
                        <sub>End date</sub>
                        <input
                          type='date'
                          {...register("to")}
                          className='w-full mt-2 p-2 h-10 rounded-lg border-gray-300'
                        />
                      </div>
                    </div>
                    <div className='gap-2 mt-4 flex flex-col'>
                      <sub>Select Transaction</sub>
                      <select
                        className='rounded-lg mt-2 border-gray-300 w-full'
                        {...register("type")}
                      >
                        <option value=''>All</option>
                        <option value='CREDIT'>Credit</option>
                        <option value='DEBIT'>Debit</option>
                      </select>
                      <sub className="mt-4">Select Project</sub>
                      <select
                        className='rounded-lg mt-2 border-gray-300 w-full'
                        {...register("project")}
                      >
                        <option value=''>All</option>
                        {userProfile?.ongoing_milestones?.map((i, index) => (
                          <option key={index} value={i.project_id}>
                            {i.project_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <button
                      type='submit'
                      className='transition-all active:scale-95 bg-native-blue px-4 py-3 mt-4 w-full text-white rounded-xl'
                    >
                      Download
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancePage;

function TransactionCard({ finance }) {
  return (
    <div className='card-sm bg-white flex my-4 gap-4 py-3 px-4 items-start hover:shadow-sm'>
      <div className='icon-container'>
        {finance.type === "Debit" ? (
          <img src={fin_withdrawal_icon} alt='' />
        ) : (
          <img src={fin_credited_icon} alt='' />
        )}
      </div>
      <div className='d-inline-flex justify-content-between w-100'>
        <div className='my-auto'>
          <b>{finance?.project_title}</b>
          <p>{finance?.time}</p>
        </div>
        <div>
          <b>₦{finance?.amount}</b>
          <p className='text-end'>{finance?.type}</p>
        </div>
      </div>
    </div>
  );
}

function DoughnutChatComponent() {
  const data = {
    labels: ["Lorem Ipsum", "Lorem Ipsum", "Lorem Ipsum"],
    datasets: [
      {
        label: "# of Votes",
        data: [30, 30, 40],
        // data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          "rgb(0,0,255)",
          "rgb(58,90,255)",
          "rgb(116,180,250)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgb(0,0,255)",
          "rgb(58,90,255)",
          "rgb(116,180,250)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return <Doughnut data={data} />;
}
