export function formatNumber(number) {
  const numberString = typeof number === "number" ? number.toString() : number;

  const [integerPart, decimalPart] = numberString.split(".");

  const formattedIntegerPart = integerPart
    .replace(/,/g, "")
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const formattedNumber = decimalPart
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;

  return formattedNumber;
}

export const getFileExtension = (filename) => {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    // No dot found in the filename
    return "";
  }
  return filename.slice(lastDotIndex + 1);
};

export const downloadFile = (fileUrl) => {
  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.setAttribute("target", "_blank"); // Open in a new tab
  anchor.setAttribute("download", ""); // Trigger download

  document.body.appendChild(anchor);
  anchor.click();

  document.body.removeChild(anchor);
};
