import React from "react";
import checkIcon from "../../assets/icons/check.svg";
import checkDoneIcon from "../../assets/icons/check-done.svg";

function HorizontalNonLinearStepper({ stepsContents }) {
  // // console.log("stepsContents", stepsContents);
  return (
    <div className="HorizontalNonLinearStepper">
      {stepsContents?.map((step, index) => {
        const { done, title } = step.headers;
        return (
          <React.Fragment key={index}>
            <div className="stepper-step">
              <div className="icon">
                <img src={done ? checkDoneIcon : checkIcon} alt="check" />
              </div>
              <div className="name">{title}</div>
            </div>
            {stepsContents.length === index + 1 ? null : (
              <div className="dash"></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default HorizontalNonLinearStepper;
